import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

import OsioOtsikko from "../../../../components/OsioOtsikko";
import Spacer from "../../../../components/Spacer";
import Tilauslomake from "./Tilauslomake/Tilauslomake";

interface CustomComponentProps {
  pt?: number;
  pr?: number;
  pb?: number;
  pl?: number;
}

const Tilaa: React.FC<CustomComponentProps> = ({ pt = 0, pr = 2, pb = 0, pl = 2 }): JSX.Element => {
  const theme = useTheme();
  return (
    <div id="tilaa">
      <Spacer sx={{ backgroundColor: theme.palette.background.paper }} />
      <Box
        sx={{
          pt,
          pr,
          pb,
          pl,
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <Container>
          <Grid
            container
            spacing={0}
            direction="column"
          >
            <OsioOtsikko
              box_sx={{ mb: 2 }}
              otsikko="Tilaa ja osallistu"
            />
            <Typography
              variant="subtitle1"
              component="p"
              color={theme.palette.text.secondary}
              sx={{
                fontWeight: 400,
                color: "#22D7C1",
              }}
              align="center"
            >
              Ilmoittaudu mukaan 19.5.2023 mennessä.
            </Typography>

            <Grid
              item
              xs={12}
              md={10}
            >
              <Box
                display="block"
                width={1}
                height={1}
              >
                <Card
                  sx={{
                    width: 1,
                    height: 1,
                    display: "flex",
                    flexDirection: "column",
                    boxShadow: "none",
                    bgcolor: "transparent",
                    backgroundImage: "none",
                  }}
                >
                  <Tilauslomake />
                </Card>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Spacer sx={{ backgroundColor: theme.palette.background.paper }} />
    </div>
  );
};

export default Tilaa;
