import React, { useState } from "react";

import axios from "axios";

import { Box, Container, FormControl, Modal, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import config from "../../../../../../config";
import CustomButton from "../../../../../components/FormComponents/CustomButton";
import { FormProvider } from "../../../../../components/FormComponents/FormContext";
import { RHFAutocompleteField } from "../../../../../components/FormComponents/RHFAutocompleteField";
import RHFCheckboxField from "../../../../../components/FormComponents/RHFCheckboxField";
import RFHTextField from "../../../../../components/FormComponents/RHFTextField";
import RHFSingleCheckboxField from "../../../../../components/FormComponents/RHFSingleCheckboxField";

import { liikevaihdon_kokoluokka } from "./liikevaihdon_kokoluokka";
import { postinumerot } from "./postinumerot";
import { toimialat } from "./toimialat";

import KIINTEISTOPALVELUJEN_VUOSIPALAUTE_2023_TOIMITUSEHDOT from "./KIINTEISTOPALVELUJEN_VUOSIPALAUTE_2023_TOIMITUSEHDOT.pdf";

import "./Tilauslomake.scss";

type Product = {
  id: number;
  name: string;
};

const products: Product[] = [
  { id: 1, name: "Vuosipalaute - asiakkaat (350 € + alv)" },
  { id: 2, name: "Vuosipalaute - henkilöstö (350 € + alv)" },
];

type FormValues = {
  y_tunnus: string;
  yrityksen_nimi: string;
  markkinointi_nimi?: string;
  kiinteistopalvelutuotannon_paatoimiala: string;
  yrityksen_postinumero: string;
  liikevaihdon_kokoluokka: string;
  tilaajan_nimi: string;
  yhteys_sposti: string;
  yhteys_puh: string;
  muuta?: string;
  selectedProducts: Record<number, boolean>;
  toimitusehdot: boolean;
  [key: string]: any;
};

type FormValuesWithSelectedProducts = FormValues & Record<string, boolean>;

const schema = yup
  .object<FormValues>({
    y_tunnus: yup.string().required("Tämä on pakollinen tieto."),
    yrityksen_nimi: yup.string().required(),
    markkinointi_nimi: yup.string(),
    kiinteistopalvelutuotannon_paatoimiala: yup.string().required("Tämä on pakollinen tieto."),
    yrityksen_postinumero: yup.string().required("Tämä on pakollinen tieto."),
    liikevaihdon_kokoluokka: yup.string().required("Tämä on pakollinen tieto."),
    tilaajan_nimi: yup.string().required("Tämä on pakollinen tieto."),
    yhteys_sposti: yup.string().email("Tarkista sähköpostiosoite").required("Tämä on pakollinen tieto."),
    yhteys_puh: yup.string().required("Tämä on pakollinen tieto."),
    selectedProducts: yup.object().test({
      name: "atLeastOneProductSelected",
      message: "Valitse vähintään yksi tutkimus.",
      test: (obj) => obj && Object.values(obj).some((selected) => selected),
    }),
    toimitusehdot: yup.boolean().oneOf([true], "Hyväksy toimitusehdot ennen tilausta."),
    muuta: yup.string(),
  })
  .required();

export default function Tilauslomake() {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), { defaultMatches: true });

  const haetaanAutomaattisesti = "Haetaan automaattisesti Y-tunnuksen perusteella";
  const [openModal, setOpenModal] = useState(false);
  const [virheLomakkeella, setVirheLomakkeella] = useState(false);
  const [modaaliOtsikko, setModaaliOtsikko] = useState("");
  const [modaaliKuvaus, setModaaliKuvaus] = useState("");

  const handleCloseModal = () => {
    setOpenModal(false);
    if (virheLomakkeella) {
      setVirheLomakkeella(false);
    } else {
      window.location.reload();
    }
  };
  const handleModal = (ok: boolean, otsikko: string, kuvaus: string) => {
    setModaaliOtsikko(otsikko);
    setModaaliKuvaus(kuvaus);
    setOpenModal(true);
  };

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    control,
    formState: { errors },
  } = useForm<FormValuesWithSelectedProducts>({
    defaultValues: {
      y_tunnus: "",
      yrityksen_nimi: "",
      markkinointi_nimi: "",
      kiinteistopalvelutuotannon_paatoimiala: "",
      yrityksen_postinumero: "",
      liikevaihdon_kokoluokka: "",
      tilaajan_nimi: "",
      yhteys_sposti: "",
      yhteys_puh: "",
      muuta: "",
      toimitusehdot: false,
      selectedProducts: {},
    },
    resolver: yupResolver(schema),
  });

  function isAnyProductSelected() {
    const selectedProducts = getValues("selectedProducts");
    return selectedProducts && Object.values(selectedProducts).some((value) => value);
  }

  const yTunnuksenVirheet = () => {
    setError("y_tunnus", { type: "manual", message: "Tarkista Y-tunnus" });
    setValue("yrityksen_nimi", haetaanAutomaattisesti);
  };

  async function haeNimi() {
    const y = getValues("y_tunnus");
    if (y) {
      try {
        const response = await fetch(`https://avoindata.prh.fi/bis/v1/${y}`);
        if (response.ok && response.status !== 404) {
          const nimi = (await response.json()).results[0].name;
          clearErrors("y_tunnus");
          setValue("yrityksen_nimi", nimi);
        } else if (response.status === 404) {
          yTunnuksenVirheet();
        }
      } catch (error) {
        yTunnuksenVirheet();
      }
    } else {
      yTunnuksenVirheet();
    }
  }

  function getCookie(name: string) {
    const value = "; " + document.cookie;
    const parts = value.split("; " + name + "=");
    return parts.length === 2 ? parts.pop()?.split(";").shift() ?? "" : "";
  }

  const csrftoken = getCookie("csrftoken");

  async function onSubmit(data: FormValues) {
    if (!isAnyProductSelected()) {
      setError("selectedProducts", {
        type: "manual",
        message: "Valitse vähintään yksi tutkimus.",
      });
      return;
    }

    // Collect the selected products into an array
    const tilatut_tuotteet: Array<{ id: number; name: string }> = [];
    for (const product of products) {
      if (data.selectedProducts[product.id]) {
        tilatut_tuotteet.push({ id: product.id, name: product.name });
      }
    }
    const { selectedProducts, ...otherData } = data;
    const modifiedData = { ...otherData, tilatut_tuotteet };

    await haeNimi();

    if (errors.y_tunnus || data.yrityksen_nimi === haetaanAutomaattisesti) {
      setVirheLomakkeella(true);
      handleModal(true, "Tarkista Y-tunnus!", "");
    } else {
      try {
        axios.defaults.headers.common["X-CSRFToken"] = csrftoken;
        await axios.post(`${config.apiUrl}`, modifiedData);
        setVirheLomakkeella(false);
        handleModal(true, "KIITOS TILAUKSESTA!", "Lähetimme tilausvahvistuksen sähköpostiosoitteeseenne.");
      } catch (error: unknown) {
        if (error instanceof Error && "response" in error && "data" in (error as any).response) {
          console.log((error as any).response.data);
        } else {
          console.log("Unexpected error format:", error);
        }
        setVirheLomakkeella(true);
        handleModal(true, "Jokin meni pieleen :(", "");
      }
    }
  }

  const modalStyle = {
    position: "absolute" as const,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "rgba(31, 48, 69, .75)",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
      >
        <Box sx={modalStyle}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            {modaaliOtsikko}
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            {modaaliKuvaus}
          </Typography>
        </Box>
      </Modal>

      <Container maxWidth="sm">
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormProvider value={{ register, control, errors }}>
            <FormControl
              fullWidth
              margin="normal"
            >
              <RFHTextField
                name="y_tunnus"
                label="Y-tunnus"
                onBlur={haeNimi}
              />

              <RFHTextField
                disabled
                name="yrityksen_nimi"
                label="Yrityksen nimi"
                value={getValues("yrityksen_nimi") || haetaanAutomaattisesti}
                sx={{ fontWeight: "500" }}
              />

              <RFHTextField
                required={false}
                name="markkinointi_nimi"
                label="(Tarvittaessa) Yrityksen markkinointinimi"
                defaultHelperText={`Vaihtoehtoinen nimi, jota haluatte käyttää kyselylomakkeella 
                  yrityksen virallisen nimen sijaan.`}
              />

              <RHFAutocompleteField
                options={toimialat}
                fieldLabel="Valitse yrityksenne päätoimiala"
                name="kiinteistopalvelutuotannon_paatoimiala"
                renderOption={(props, option) => (
                  <span
                    {...props}
                    style={{ backgroundColor: theme.palette.background.paper }}
                  >
                    {option.label}
                  </span>
                )}
              />

              <RHFAutocompleteField
                options={liikevaihdon_kokoluokka}
                fieldLabel="Valitse yrityksenne liikevaihdon kokoluokka"
                name="liikevaihdon_kokoluokka"
                renderOption={(props, option) => (
                  <span
                    {...props}
                    style={{ backgroundColor: theme.palette.background.paper }}
                  >
                    {option.label}
                  </span>
                )}
              />

              <RHFAutocompleteField
                options={postinumerot}
                fieldLabel="Yrityksen toiminta-aluetta parhaiten kuvaava postinumero"
                name="yrityksen_postinumero"
                renderOption={(props, option) => (
                  <span
                    {...props}
                    style={{ backgroundColor: theme.palette.background.paper }}
                  >
                    {option.label}
                  </span>
                )}
              />

              <RFHTextField
                name="tilaajan_nimi"
                label="Tilaajan nimi"
              />

              <RFHTextField
                name="yhteys_sposti"
                label="Tilaajan sähköpostiosoite"
              />

              <RFHTextField
                name="yhteys_puh"
                label="Tilaajan puhelinnumero"
              />

              <RHFCheckboxField
                fieldNamePrefix="selectedProducts"
                options={products}
                fieldLabel="Tilattavat tutkimukset *"
                errorName="selectedProducts"
              />

              <RFHTextField
                required={false}
                name="muuta"
                label="Muut tilaukseen liittyvät huomiot"
                multiline
              />

              <RHFSingleCheckboxField
                name="toimitusehdot"
                fieldLabel="Toimitusehdot *"
                optionLabel="Hyväksyn toimitusehdot"
                errorName="toimitusehdot"
                pdfLink={KIINTEISTOPALVELUJEN_VUOSIPALAUTE_2023_TOIMITUSEHDOT}
              />

              <CustomButton
                id="lahetaTilaus"
                fullWidth={!isMd}
                onClick={haeNimi}
                buttonText="Lähetä tilaus"
                type="submit"
              />
            </FormControl>
          </FormProvider>
        </form>
      </Container>
    </>
  );
}
