import React from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import CardActions from "@mui/material/CardActions";
import Spacer from "../../../../components/Spacer";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

import OsioOtsikko from "../../../../components/OsioOtsikko";
interface CustomComponentProps {
  pt?: number;
  pr?: number;
  pb?: number;
  pl?: number;
}

const Hinta: React.FC<CustomComponentProps> = ({ pt = 0, pr = 2, pb = 0, pl = 2 }): JSX.Element => {
  const theme = useTheme();

  return (
    <div id="hinta">
      <Spacer />
      <Box sx={{ pt, pr, pb, pl }}>
        <OsioOtsikko otsikko="Hinta" />
        <Container>
          <Grid
            container
            spacing={4}
            sx={{
              justifyContent: "center",
            }}
          >
            <Grid
              item
              xs={12}
              md={5}
            >
              <Box
                component={Card}
                height={1}
                display="flex"
                flexDirection="column"
                boxShadow={0}
                border={`1px solid ${theme.palette.divider}`}
              >
                <CardContent
                  sx={{
                    padding: { sm: 4 },
                  }}
                >
                  <Box
                    marginBottom={0}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Typography
                      sx={{
                        textTransform: "uppercase",
                        mb: 2,
                        textAlign: "center",
                      }}
                      variant="h5"
                      color={theme.palette.text.secondary}
                    >
                      Vuosipalaute - asiakkaat
                    </Typography>
                    <Box
                      display="flex"
                      alignItems="flex-start"
                    >
                      <Typography
                        variant="h2"
                        color="primary"
                        gutterBottom
                      >
                        <Box
                          component="span"
                          fontWeight={600}
                        >
                          350
                        </Box>
                      </Typography>
                      <Typography
                        variant="h4"
                        color="primary"
                      >
                        <Box
                          component="span"
                          fontWeight={600}
                          marginLeft={1 / 2}
                        >
                          €
                        </Box>
                      </Typography>
                    </Box>
                    <Typography
                      variant="h5"
                      color={theme.palette.text.secondary}
                    >
                      + alv
                    </Typography>
                  </Box>
                </CardContent>
                <Box flexGrow={1} />
                <CardActions
                  sx={{
                    justifyContent: "center",
                    padding: 4,
                    paddingTop: 0,
                    marginBottom: 2,
                  }}
                >
                  <Button
                    size="large"
                    variant="contained"
                    endIcon={<ArrowForwardIcon />}
                    href="#tilaa"
                    sx={{
                      textTransform: "uppercase",
                      color: theme.palette.common.black,
                      border: "2px solid " + theme.palette.primary.main,
                      "&:hover": {
                        backgroundColor: "transparent",
                        color: theme.palette.primary.main,
                        border: "2px solid " + theme.palette.primary.main,
                      },
                    }}
                  >
                    Tilaa ja osallistu
                  </Button>
                </CardActions>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
            >
              <Box
                component={Card}
                height={1}
                display="flex"
                flexDirection="column"
                boxShadow={0}
                border={`1px solid ${theme.palette.divider}`}
              >
                <CardContent
                  sx={{
                    padding: { sm: 4 },
                  }}
                >
                  <Box
                    marginBottom={0}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Typography
                      sx={{
                        textTransform: "uppercase",
                        mb: 2,
                        textAlign: "center",
                      }}
                      variant="h5"
                      color={theme.palette.text.secondary}
                    >
                      Vuosipalaute - henkilöstö
                    </Typography>
                    <Box
                      display="flex"
                      alignItems="flex-start"
                    >
                      <Typography
                        variant="h2"
                        color="primary"
                        gutterBottom
                      >
                        <Box
                          component="span"
                          fontWeight={600}
                        >
                          350
                        </Box>
                      </Typography>
                      <Typography
                        variant="h4"
                        color="primary"
                      >
                        <Box
                          component="span"
                          fontWeight={600}
                          marginLeft={1 / 2}
                        >
                          €
                        </Box>
                      </Typography>
                    </Box>
                    <Typography
                      variant="h5"
                      color={theme.palette.text.secondary}
                    >
                      + alv
                    </Typography>
                  </Box>
                </CardContent>
                <Box flexGrow={1} />
                <CardActions
                  sx={{
                    justifyContent: "center",
                    padding: 4,
                    paddingTop: 0,
                    marginBottom: 2,
                  }}
                >
                  <Button
                    size="large"
                    variant="contained"
                    endIcon={<ArrowForwardIcon />}
                    href="#tilaa"
                    sx={{
                      textTransform: "uppercase",
                      color: theme.palette.common.black,
                      border: "2px solid " + theme.palette.primary.main,
                      "&:hover": {
                        backgroundColor: "transparent",
                        color: theme.palette.primary.main,
                        border: "2px solid " + theme.palette.primary.main,
                      },
                    }}
                  >
                    Tilaa ja osallistu
                  </Button>
                </CardActions>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  );
};

export default Hinta;
