import { Box } from "@mui/material";

import NagigationButtons from "../../CommonFormComponents/KyselysivunOsat/NavigationButtons";
import AsiakasNakokulma from "./AsiakasNakokulma";
import { ValiSivuProps } from "../../CommonFormComponents/types";
import Yritysnimi from "../../CommonFormComponents/KyselysivunOsat/Yritysnimi";
import { useAppState } from "../../CommonFormComponents/state";

const Asiakas: React.FC<ValiSivuProps> = ({ edellinenSivu, seuraavaSivu, buttonSeuraava }) => {
  const { state } = useAppState();
  return (
    <Box>
      <Yritysnimi yritysnimi={state.yritysNimi || ""} />
      <AsiakasNakokulma />
      <NagigationButtons
        edellinenSivu={edellinenSivu}
        seuraavaSivu={seuraavaSivu}
      />
    </Box>
  );
};

export default Asiakas;
