import React from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import LogoutButton from "./LogoutButton";

interface Props {
  onClose: () => void;
  open: boolean;
}

const ProtectedSidebar = ({ open, onClose }: Props): JSX.Element => {
  const theme = useTheme();

  return (
    <>
      <Drawer
        anchor="left"
        onClose={() => onClose()}
        open={open}
        variant="temporary"
        PaperProps={{
          sx: {
            backgroundColor: theme.palette.background.default,
            width: 256,
          },
        }}
      >
        <Box height="100%">
          <Box width={1}>
            <Link
              to="#home"
              style={{ textDecoration: "none" }}
            >
              <IconButton
                size="large"
                disabled
              >
                <Typography
                  variant="h6"
                  sx={{
                    flexGrow: 1,
                    color: theme.palette.text.primary,
                    fontWeight: "bold",
                    textTransform: "uppercase",
                    textDecoration: "none",
                    marginLeft: 1,
                  }}
                >
                  Kiinteistöpalvelujen vuosipalaute 2023
                </Typography>
              </IconButton>
            </Link>
          </Box>
          <Box padding={2}>
            <LogoutButton />
            {/* TÄHÄN SIVUPALKIN LINKIT */}
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default ProtectedSidebar;
