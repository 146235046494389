import { Theme, PaletteMode, responsiveFontSizes } from "@mui/material";
import { createTheme, ComponentsOverrides } from "@mui/material/styles";
import "@fontsource/exo-2";

const getTheme = (): Theme =>
  responsiveFontSizes(
    createTheme({
      palette: {
        mode: "dark" as PaletteMode,
        background: {
          default: "#545759", // toimialadatan netti (harmaa)
          paper: "rgba(31, 48, 69, .65)", // vari_1 (vaaleampi)
        },
        text: {
          primary: "rgb(255, 255, 255)",
          secondary: "rgb(207, 207, 207)",
        },
        primary: {
          main: "#22D7C1",
          contrastText: "rgb(100, 101, 98)",
        },
        divider: "rgba(145, 158, 171, 0.24)",
      },
      typography: {
        fontFamily: '"Exo 2", sans-serif',
      },
      components: {
        MuiPaper: {
          styleOverrides: {
            // Add your default styles here
            root: {
              // backgroundColor: "#fff",
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            root: {
              fontWeight: 600,
              borderRadius: 0,
              paddingTop: 10,
              paddingBottom: 10,
            },
          } as ComponentsOverrides["MuiButton"],
        },
        MuiInputBase: {
          styleOverrides: {
            root: {
              borderRadius: 0,
            },
          } as ComponentsOverrides["MuiInputBase"],
        },
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              borderRadius: 0,
            },
            input: {
              borderRadius: 0,
            },
          } as ComponentsOverrides["MuiOutlinedInput"],
        },
        MuiCard: {
          styleOverrides: {
            root: {
              borderRadius: 0,
            },
          } as ComponentsOverrides["MuiCard"],
        },
        MuiTooltip: {
          styleOverrides: {
            tooltip: {
              fontSize: "1rem",
              padding: "1rem",
            },
          },
        },
      },
    })
  );

export default getTheme;
