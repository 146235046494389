import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

interface CustomComponentProps {
  otsikko: string;
  kuva: string;
  kuvaus: string;
  alt?: string;
}

const Osa: React.FC<CustomComponentProps> = ({ otsikko, kuva, kuvaus, alt }) => {
  const theme = useTheme();
  return (
    <Grid
      item
      xs={12}
      sm={4}
    >
      <Box
        component={Card}
        padding={4}
        width={1}
        height={1}
        bgcolor={theme.palette.background.paper}
        sx={{
          "&:hover": {
            color: theme.palette.common.white,
          },
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
        >
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              fontWeight: 500,
            }}
          >
            <span style={{ textTransform: "uppercase" }}>{otsikko}</span>
          </Typography>
          <Typography
            color="inherit"
            sx={{
              minHeight: 100,
              color: theme.palette.text.secondary,
            }}
          >
            {kuvaus}
          </Typography>
        </Box>
        <Box
          display="block"
          width={1}
          height={1}
        >
          <Card
            sx={{
              width: 1,
              height: 1,
              paddingBottom: 1,
              bgcolor: "transparent",
              backgroundImage: "none",
            }}
          >
            <div
              style={{
                position: "relative",
                height: 0,
                paddingTop: "56.25%",
                marginBottom: 4,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                alt={alt}
                src={kuva}
                style={{
                  position: "absolute",
                  top: "0%",
                  left: "50%",
                  transform: "translate(-50%, 0%)",
                  maxHeight: "100%",
                  maxWidth: "100%",
                  borderRadius: 8,
                  marginTop: "20px",
                }}
              />
            </div>
          </Card>
        </Box>
      </Box>
    </Grid>
  );
};

export default Osa;
