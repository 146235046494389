import React from "react";

import { Theme } from "@mui/material/styles";
import { SxProps } from "@mui/system";
import { grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import { FormHelperText } from "@mui/material";

import RHFTextField from "./kyselyRHFTextField";

interface AvokenttaProps {
  sx?: SxProps<Theme>;
  name: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  helperText?: string | null;
}

const Avokentta: React.FC<AvokenttaProps> = ({ sx, name, value, onChange, label, helperText }) => {
  const { t } = useTranslation();
  return (
    <>
      <RHFTextField
        name={name}
        value={value}
        onChange={onChange}
        label={t(label)}
        sx={{ mt: 5, ...sx }}
        multiline
        rows={1}
        required={false}
        customInputLabelProps={{
          fontSize: "1.25rem",
          transform: "translate(14px, -35px) scale(0.95)",
        }}
        darkColors={true}
        focusedLabelColor="rgb(100, 101, 98)"
        underlineColor={grey[500]}
      />

      {helperText && (
        <FormHelperText
          sx={{
            mt: -1,
            ml: 2,
            fontSize: "0.9rem",
            color: grey[800],
          }}
        >
          {helperText}
        </FormHelperText>
      )}
    </>
  );
};

export default Avokentta;
