import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

interface CustomComponentProps {
  otsikko: string;
  bullet_1: string;
  bullet_2: string;
  bullet_3: string;
  kuva: string;
  alt?: string;
}

const OsaBulleteilla: React.FC<CustomComponentProps> = ({ otsikko, bullet_1, bullet_2, bullet_3, kuva, alt }) => {
  const theme = useTheme();
  return (
    <Grid
      item
      xs={12}
      sm={4}
    >
      <Box
        component={Card}
        padding={4}
        width={1}
        height={1}
        bgcolor={theme.palette.background.paper}
        sx={{
          "&:hover": {
            color: theme.palette.common.white,
          },
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
        >
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              fontWeight: 500,
            }}
          >
            <span style={{ textTransform: "uppercase" }}>{otsikko}</span>
          </Typography>
        </Box>
        <Box
          display="block"
          width={1}
          height={1}
        >
          <ul style={{ margin: "1rem", padding: 0, marginTop: ".3rem", marginBottom: 0 }}>
            <li>
              <Typography
                color="inherit"
                sx={{
                  mb: 1,
                  mt: 0,
                  color: theme.palette.text.secondary,
                }}
              >
                {bullet_1}
              </Typography>
            </li>
            <li>
              <Typography
                color="inherit"
                sx={{
                  mb: 1,
                  color: theme.palette.text.secondary,
                }}
              >
                {bullet_2}
              </Typography>
            </li>
            <li>
              <Typography
                color="inherit"
                sx={{
                  color: theme.palette.text.secondary,
                }}
              >
                {bullet_3}
              </Typography>
            </li>
          </ul>
          <div
            style={{
              position: "relative",
              height: 0,
              paddingTop: "56.25%",
              marginBottom: 4,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              alt={alt}
              src={kuva}
              style={{
                position: "absolute",
                top: "0%",
                left: "50%",
                transform: "translate(-50%, 0%)",
                maxHeight: "100%",
                maxWidth: "100%",
                borderRadius: 8,
                marginTop: "20px",
              }}
            />
          </div>
        </Box>
      </Box>
    </Grid>
  );
};

export default OsaBulleteilla;
