import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';

import ValiotsikkoJaTekstikappaleet from './ValiotsikkoJaTekstikappaleet';

interface CustomComponentProps {
  otsikko0: string;
  kuvaus01: string;
  otsikko1: string;
  kuvaus11: string;
  kuvaus12: string;
  otsikko2: string;
  kuvaus21: string;
  kuvaus22: string;
  kuvaus23: string;
}

const KolmeVaihetta: React.FC<CustomComponentProps> = ({
  otsikko0,
  kuvaus01,
  otsikko1,
  kuvaus11,
  kuvaus12,
  otsikko2,
  kuvaus21,
  kuvaus22,
  kuvaus23,
}) => {
  const theme = useTheme();
  return (
    <Grid
      item
      md={8}
      xs={12}
    >
      <Box
        sx={{
          padding: 2,
          backgroundColor: theme.palette.background.paper,
          margin: 0,
        }}
        component={Card}
        height={1}
        display="flex"
        flexDirection="column"
        boxShadow={0}
      >
        <CardContent>
          <Box
            marginBottom={0}
            display="flex"
            flexDirection="column"
          >
            <ValiotsikkoJaTekstikappaleet
              otsikko={otsikko0}
              kuvaus_1={kuvaus01}
              theme={theme}
            />

            <ValiotsikkoJaTekstikappaleet
              otsikko={otsikko1}
              kuvaus_1={kuvaus11}
              kuvaus_2={kuvaus12}
              theme={theme}
            />

            <ValiotsikkoJaTekstikappaleet
              otsikko={otsikko2}
              kuvaus_1={kuvaus21}
              kuvaus_2={kuvaus22}
              kuvaus_3={kuvaus23}
              theme={theme}
            />
          </Box>
        </CardContent>
      </Box>
    </Grid>
  );
};

export default KolmeVaihetta;
