import React from 'react';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';

interface TekstikappaleProps {
  kuvaus: string;
  theme: Theme;
  sx?: SxProps<Theme>;
}

const Tekstikappale: React.FC<TekstikappaleProps> = ({ sx, kuvaus, theme }) => {
  return (
    <>
      <Typography
        sx={{
          marginBottom: 1,
          lineHeight: 1.5,
          ...sx,
        }}
        variant="subtitle1"
        marginTop={theme.spacing(1)}
        color={theme.palette.text.secondary}
      >
        {kuvaus}
      </Typography>
    </>
  );
};

export default Tekstikappale;
