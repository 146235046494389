import React, { useEffect } from "react";
import axios, { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { FieldError, FormProvider, useForm } from "react-hook-form";

import { Theme } from "@mui/material/styles";
import { SxProps } from "@mui/system";
import { Box } from "@mui/material";

import { useAppState, STATE_KEY } from "../CommonFormComponents/state";
import { useTaustaHandleChange } from "../CommonFormComponents/useHandleChange";
import { Kysymys, AsiakasTaustaFormValues } from "../CommonFormComponents/types";

import config from "../../../../config";
import TaustatietoKomponentit from "../CommonFormComponents/Tausta/TaustatietoKomponentit";
import Yritysnimi from "../CommonFormComponents/KyselysivunOsat/Yritysnimi";
import NagigationButtons from "../CommonFormComponents/KyselysivunOsat/NavigationButtons";

interface TaustatietoSivuProps {
  sx?: SxProps<Theme>;
  edellinenSivu: string;
  seuraavaSivu: string;
  lahetaData: boolean;
}

const AsiakasTaustatietoSivu: React.FC<TaustatietoSivuProps> = ({ sx, edellinenSivu, seuraavaSivu, lahetaData }) => {
  const { state, setIsSubmitted, formId, isSubmitted, resetState } = useAppState();
  const handleChange = useTaustaHandleChange();
  const navigate = useNavigate();

  const urlPolku = "/asiakaskysely/" + state.identifier + "/";
  const seuraavaSivuUrl = seuraavaSivu !== undefined ? urlPolku + seuraavaSivu : "";

  const formMethods = useForm<AsiakasTaustaFormValues>({});
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const getCSRFToken = () => {
    const csrfCookie = document.cookie.split(";").find((cookie) => cookie.trim().startsWith("csrftoken="));
    if (csrfCookie) {
      return csrfCookie.split("=")[1];
    }
    return null;
  };

  useEffect(() => {
    return () => {
      if (isSubmitted) {
        localStorage.removeItem(`${STATE_KEY}-${formId}`);
      }
    };
  }, [isSubmitted, formId]);

  const onSubmit = async () => {
    try {
      const csrfToken = getCSRFToken();
      const response = await axios.post(`${config.baseApiUrl}/asiakasdata/`, state, {
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken,
        },
      });
      if (response.status === 201) {
        setIsSubmitted(true);
        localStorage.removeItem(`${STATE_KEY}-${formId}`);
        if (resetState) {
          resetState();
        }
        navigate(seuraavaSivuUrl);
      } else {
        console.log("failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
      const axiosError = error as AxiosError;
      if (axiosError.response && axiosError.response.data) {
        console.log("Error data:", axiosError.response.data);
      }
      if (error instanceof Error) {
        console.log("Error:", error.message);
      } else {
        console.log("Error.");
      }
    }
  };

  const name_t1 = "tausta_1"; // Kiinteistotyyppi
  const name_t2 = "tausta_2"; // Asema Kiinteistössä
  const name_t3 = "tausta_3"; // Kauanko olet ollut asiakkaamme?
  const name_t4 = "tausta_4"; // Mitä odotat yhteistyöltä kanssamme?
  const name_t5 = "tausta_5"; // Voit halutessasi vastata omalla nimelläsi
  const name_t6 = "tausta_6"; // Yhteydenottopyyntö (muunnetaan booleaniksi lähetettäessä)
  const name_t7 = "tausta_7"; // Puhelinnumero
  const name_t8 = "tausta_8"; // Sähköposti
  const name_t9 = "tausta_9"; // Lupa vastauksen käyttämiseen markkinoinnissa
  const name_t10 = "tausta_10"; // Etunimi
  const name_t11 = "tausta_11"; // Sukunimi
  const name_t12 = "tausta_12"; // Organisaatio
  const value_t1 = state[name_t1];
  const value_t2 = state[name_t2];
  const value_t3 = state[name_t3];
  const value_t4 = state[name_t4];
  const value_t5 = state[name_t5];
  const value_t6 = state[name_t6];
  const value_t7 = state[name_t7];
  const value_t8 = state[name_t8];
  const value_t9 = state[name_t9];
  const value_t10 = state[name_t10];
  const value_t11 = state[name_t11];
  const value_t12 = state[name_t12];

  const valueLabelCreator = (options: string[]) =>
    options.map((option, index) => ({
      value: String(index + 1),
      label: option,
    }));

  const kiinteistotyyppi_options = ["Kerrostalo", "Rivitalo", "Liikekiinteistö", "Teollisuus", "Muu", "Ei vastausta"];
  const kiinteistotyyppi_options_obj = valueLabelCreator(kiinteistotyyppi_options);

  const asema_options = ["Isännöitsijä", "Manageri", "Omistaja", "Käyttäjä", "Muu", "Ei vastausta"];
  const asema_options_obj = valueLabelCreator(asema_options);

  const asiakkaana_options = ["Alle vuoden", "1-3 vuotta", "Yli 3 vuotta", "Ei vastausta"];
  const asiakkaana_options_obj = valueLabelCreator(asiakkaana_options);

  const yhteydenottopyynto_options = ["Haluan että minuun otetaan yhteyttä"];
  const yhteydenottopyynto_options_obj = valueLabelCreator(yhteydenottopyynto_options);

  const lupa_options = [
    "Annan luvan, että yritys voi hyödyntää palautettani markkinointitarkoituksessa anonyymisti",
    "Annan luvan, että yritys voi hyödyntää palautettani markkinointitarkoituksessa alla olevilla tiedoilla",
    "Palautettani ei saa hyödyntää markkinointitarkoituksessa",
  ];
  const lupa_options_obj = valueLabelCreator(lupa_options);

  const showYhteysdenottoConditionalFields = value_t6[0] === "1";
  if (!showYhteysdenottoConditionalFields) {
    if (state[name_t7] !== "" || state[name_t8] !== "") {
      state[name_t7] = "";
      state[name_t8] = "";
    }
  }
  const showMarkkinointilupaConditionalFields = value_t9 === lupa_options_obj[1].value;
  if (!showMarkkinointilupaConditionalFields) {
    // jos ei anneta markkinointilupaa, poistetaan mahdollisesti aiemmin annetut tiedot
    if (state[name_t10] !== "" || state[name_t11] !== "" || state[name_t12] !== "") {
      state[name_t10] = "";
      state[name_t11] = "";
      state[name_t12] = "";
    }
  }

  const taustat: Kysymys[] = [
    {
      type: "paaOtsikko",
      text: "asiakas.taustat.otsikko",
      name: "paaOtsikko",
      value: "",
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      handleChange: () => {},
    },
    {
      // Kiinteistotyyppi
      type: "radio",
      name: name_t1,
      value: value_t1,
      defaultOption: value_t1,
      text: "asiakas.taustat.t1.otsikko",
      options: kiinteistotyyppi_options_obj,
      description: "asiakas.taustat.t1.helper",
      handleChange,
      gridSizeMd: 4,
      gridSizeSm: 6,
    },
    {
      // Asema Kiinteistössä
      type: "radio",
      name: name_t2,
      value: value_t2,
      defaultOption: value_t2,
      text: "asiakas.taustat.t2.otsikko",
      options: asema_options_obj,
      handleChange,
      gridSizeMd: 4,
      gridSizeSm: 6,
    },
    {
      // Kauanko olet ollut asiakkaamme?
      type: "radio",
      name: name_t3,
      value: value_t3,
      defaultOption: value_t3,
      text: "asiakas.taustat.t3.otsikko",
      options: asiakkaana_options_obj,
      handleChange,
      gridSizeMd: 4,
      gridSizeSm: 6,
    },
    {
      // Mitä odotat yhteistyöltä kanssamme?
      type: "textfield",
      name: name_t4,
      value: value_t4,
      text: "asiakas.taustat.t4.otsikko",
      description: "asiakas.taustat.t4.helper",
      handleChange,
    },
    {
      // Voit halutessasi vastata omalla nimelläsi
      type: "textfield",
      name: name_t5,
      value: value_t5,
      text: "asiakas.taustat.t5.otsikko",
      description: "asiakas.taustat.t5.helper",
      handleChange,
    },
    {
      // Yhteydenottopyyntö (muunnetaan booleaniksi lähetettäessä)
      type: "checkbox",
      name: name_t6,
      value: value_t6,
      text: "asiakas.taustat.t6.otsikko",
      handleChange: handleChange,
      options: yhteydenottopyynto_options_obj,
      sx: { mt: 5 },
    },
    ...(showYhteysdenottoConditionalFields
      ? [
          {
            type: "textfield",
            name: name_t7,
            value: value_t7,
            text: "asiakas.taustat.t7.otsikko",
            handleChange: handleChange,
            sx: { mt: 3 },
          },
          {
            type: "textfield",
            name: name_t8,
            value: value_t8,
            text: "asiakas.taustat.t8.otsikko",
            handleChange: handleChange,
            sx: { mt: 2 },
          },
        ]
      : []),
    {
      type: "radio",
      name: name_t9,
      value: value_t9,
      defaultOption: value_t9 ? value_t9 : lupa_options_obj[0].value,
      text: "asiakas.taustat.t9.otsikko",
      options: lupa_options_obj,
      handleChange: handleChange,
    },
    ...(showMarkkinointilupaConditionalFields
      ? [
          {
            type: "textfield",
            name: name_t10,
            value: value_t10,
            text: "asiakas.taustat.t10.otsikko",
            handleChange: handleChange,
          },
          {
            type: "textfield",
            name: name_t11,
            value: value_t11,
            text: "asiakas.taustat.t11.otsikko",
            handleChange: handleChange,
            sx: { mt: 2 },
          },
          {
            type: "textfield",
            name: name_t12,
            value: value_t12,
            text: "asiakas.taustat.t12.otsikko",
            handleChange: handleChange,
            sx: { mt: 2 },
          },
        ]
      : []),
  ].filter((question) => question !== undefined);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ textAlign: "left" }}>
          <Yritysnimi yritysnimi={state.yritysNimi || ""} />
          <TaustatietoKomponentit
            maarittely_json={taustat}
            register={register}
            errors={errors as Record<string, FieldError>}
          />
          <NagigationButtons
            edellinenSivu={edellinenSivu}
            showEdellinen={true}
            seuraavaSivu={seuraavaSivu}
            lahetaData={lahetaData}
            buttonSeuraava="Lähetä vastaukset"
            type="submit"
          />
        </Box>
      </form>
    </FormProvider>
  );
};

export default AsiakasTaustatietoSivu;
