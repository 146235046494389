import React from "react";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

interface CustomComponentProps {
  otsikko: string;
  ikoni: string;
  kuvaus: string;
}

const Perustelu: React.FC<CustomComponentProps> = ({ otsikko, ikoni, kuvaus }) => {
  const theme = useTheme();
  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={4}
    >
      <Box
        component={Card}
        padding={4}
        width={1}
        height={1}
      >
        <Box
          display="flex"
          flexDirection="column"
        >
          <Box
            component={Avatar}
            width={50}
            height={50}
            marginBottom={2}
            bgcolor={theme.palette.primary.main}
            color={theme.palette.background.paper}
          >
            <Icon className="material-icons-outlined">{ikoni}</Icon>
          </Box>
          <Typography
            variant="h6"
            gutterBottom
            sx={{ fontWeight: 500 }}
          >
            {otsikko}
          </Typography>
          <Typography color={theme.palette.text.secondary}>{kuvaus}</Typography>
        </Box>
      </Box>
    </Grid>
  );
};

export default Perustelu;
