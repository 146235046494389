import React from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

import CustomButton from "../../../components/CustomButton";

interface Props {
  onClose: () => void;
  open: boolean;
}

const Sidebar = ({ open, onClose }: Props): JSX.Element => {
  const theme = useTheme();

  return (
    <>
      <Drawer
        anchor="left"
        onClose={() => onClose()}
        open={open}
        variant="temporary"
        PaperProps={{
          sx: {
            backgroundColor: theme.palette.background.default,
            width: 256,
          },
        }}
      >
        <Box height="100%">
          <Box width={1}>
            <Link
              to="#home"
              style={{ textDecoration: "none" }}
            >
              <IconButton
                size="large"
                disabled
              >
                <Typography
                  variant="h6"
                  sx={{
                    flexGrow: 1,
                    color: theme.palette.text.primary,
                    fontWeight: "bold",
                    textTransform: "uppercase",
                    textDecoration: "none",
                    marginLeft: 1,
                  }}
                >
                  Kiinteistöpalvelujen vuosipalaute 2023
                </Typography>
              </IconButton>
            </Link>
          </Box>
          <Box padding={2}>
            <Box paddingY={2}>
              <CustomButton
                href="#miksi"
                text="Miksi osallistua?"
                // text="Miksi?"
              />
              <Box paddingY={1}>
                <CustomButton
                  href="#toteutus"
                  text="Toteutus- tapa"
                  // text="Toteutus"
                />
              </Box>
              <Box paddingY={1}>
                <CustomButton
                  href="#sisalto"
                  text="Sisällön kuvaus"
                  // text="Sisältö"
                />
              </Box>
              <Box paddingY={1}>
                <CustomButton
                  href="#hlosto"
                  text="Henkilöstö- tutkimus"
                />
              </Box>
              <Box paddingY={1}>
                <CustomButton
                  href="#hinta"
                  text="Hinta"
                />
              </Box>
              <Box paddingY={1}>
                <CustomButton
                  href="#tietoa"
                  text="Tietoa meistä"
                  // text="Me"
                />
              </Box>
              <Box paddingY={1}>
                <CustomButton
                  href="#tilaa"
                  text="Tilaa ja osallistu"
                  // text="Tilaa"
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default Sidebar;
