import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import Spacer from "../../../../components/Spacer";

import Fakta from "./Fakta";
import OsioOtsikko from "../../../../components/OsioOtsikko";
interface CustomComponentProps {
  pt?: number;
  pr?: number;
  pb?: number;
  pl?: number;
}
const Meista: React.FC<CustomComponentProps> = ({ pt = 0, pr = 2, pb = 0, pl = 2 }): JSX.Element => {
  const theme = useTheme();
  return (
    <div id="tietoa">
      <Spacer />
      <Box sx={{ pt, pr, pb, pl }}>
        <OsioOtsikko otsikko="Tietoa meistä ja yhteystiedot" />
        <Container>
          <Typography
            variant="subtitle1"
            align="center"
            color={theme.palette.text.secondary}
            marginTop={theme.spacing(1)}
            gutterBottom
            sx={{ marginBottom: 2 }}
          >
            <Grid
              container
              spacing={4}
              sx={{ justifyContent: "center" }}
            >
              <Grid
                item
                xs={12}
                md={8}
              >
                <Link
                  href="https://toimialadata.fi"
                  color="inherit"
                >
                  Suomen Toimialadata Oy
                </Link>{" "}
                on erikoistunut toimialakohtaisiin mittaristoihin. Mahdollistamme yksinkertaiset kehittämisen työkalut
                yrityksille, joille perinteiset kyselytutkimukset ovat kalliita ja raskaita.
              </Grid>
            </Grid>
          </Typography>

          <Box>
            <Typography
              variant="subtitle1"
              align="center"
              color={theme.palette.text.secondary}
              gutterBottom
              sx={{
                color: "#22D7C1",
                fontWeight: 700,
                margin: 0,
              }}
            >
              Pekka Harjunkoski
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="subtitle1"
              align="center"
              color={theme.palette.text.secondary}
              gutterBottom
              sx={{
                color: "#22D7C1",
              }}
            >
              pekka.harjunkoski@toimialadata.fi
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="subtitle1"
              align="center"
              color={theme.palette.text.secondary}
              gutterBottom
              sx={{
                marginBottom: 4,
                color: "#22D7C1",
              }}
            >
              050 599 0079
            </Typography>
          </Box>
          <Box>
            <Grid
              container
              spacing={4}
            >
              <Fakta
                numero={1500}
                suffix={"+"}
                kuvaus={"tyytyväistä asiakasta"}
              />
              <Fakta
                numero={100}
                suffix={"%"}
                kuvaus={"Suomalainen"}
              />
              <Fakta
                numero={20}
                suffix={"+"}
                kuvaus={"vuoden kokemuksella"}
              />
            </Grid>
          </Box>
        </Container>
      </Box>
      <Spacer />
    </div>
  );
};

export default Meista;
