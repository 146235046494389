import { ReactElement } from "react";

import KyselySivu from "../CommonFormComponents/KyselysivunOsat/KyselySivu";
import TervetuloaSivu from "./TervetuloaSivu";
import Asiakas from "./Valisivut/Asiakas";
import Henkilosto from "./Valisivut/Henkilosto";
import KiitosSivu from "../CommonFormComponents/Kiitos/KiitosSivu";
import HenkilostoTaustatietoSivu from "./HenkilostoTaustatietoSivu";

export type KyselyRakenne = {
  polku: string;
  element: ReactElement;
}[];

export const Henkilostokyselyrakenne: KyselyRakenne = [
  {
    polku: "",
    element: (
      <TervetuloaSivu
        seuraavaSivu="a"
        buttonSeuraava="buttonAloitetaan"
      />
    ),
  },
  {
    polku: "a",
    element: (
      <Asiakas
        edellinenSivu=""
        seuraavaSivu="1"
      />
    ),
  },
  {
    polku: "1",
    element: (
      <KyselySivu
        kysymys="1"
        otsikko="henkilosto.k1.otsikko"
        eriMielta="henkilosto.k1.eriMielta"
        samaaMielta="henkilosto.k1.samaaMielta"
        avo1="henkilosto.mikaHyvin"
        avo2="henkilosto.mitenVoisitParantaa"
        avo3="henkilosto.mitenParantaaYhdessa"
        edellinenSivu="a"
        seuraavaSivu="2"
      />
    ),
  },
  {
    polku: "2",
    element: (
      <KyselySivu
        kysymys="2"
        otsikko="henkilosto.k2.otsikko"
        eriMielta="henkilosto.k2.eriMielta"
        samaaMielta="henkilosto.k2.samaaMielta"
        avo1="henkilosto.mikaHyvin"
        avo2="henkilosto.mitenVoisitParantaa"
        avo3="henkilosto.mitenParantaaYhdessa"
        edellinenSivu="1"
        seuraavaSivu="3"
      />
    ),
  },
  {
    polku: "3",
    element: (
      <KyselySivu
        kysymys="3"
        otsikko="henkilosto.k3.otsikko"
        eriMielta="henkilosto.k3.eriMielta"
        samaaMielta="henkilosto.k3.samaaMielta"
        avo1="henkilosto.mikaHyvin"
        avo2="henkilosto.mitenVoisitParantaa"
        avo3="henkilosto.mitenParantaaYhdessa"
        edellinenSivu="2"
        seuraavaSivu="4"
      />
    ),
  },
  {
    polku: "4",
    element: (
      <KyselySivu
        kysymys="4"
        otsikko="henkilosto.k4.otsikko"
        eriMielta="henkilosto.k4.eriMielta"
        samaaMielta="henkilosto.k4.samaaMielta"
        avo1="henkilosto.mikaHyvin"
        avo2="henkilosto.mitenVoisitParantaa"
        avo3="henkilosto.mitenParantaaYhdessa"
        edellinenSivu="3"
        seuraavaSivu="5"
      />
    ),
  },
  {
    polku: "5",
    element: (
      <KyselySivu
        kysymys="5"
        otsikko="henkilosto.k5.otsikko"
        eriMielta="henkilosto.k5.eriMielta"
        samaaMielta="henkilosto.k5.samaaMielta"
        avo1="henkilosto.mikaHyvin"
        avo2="henkilosto.mitenVoisitParantaa"
        avo3="henkilosto.mitenParantaaYhdessa"
        edellinenSivu="4"
        seuraavaSivu="o"
      />
    ),
  },
  {
    polku: "o",
    element: (
      <Henkilosto
        edellinenSivu="5"
        seuraavaSivu="6"
      />
    ),
  },
  {
    polku: "6",
    element: (
      <KyselySivu
        kysymys="6"
        otsikko="henkilosto.k6.otsikko"
        eriMielta="henkilosto.k6.eriMielta"
        samaaMielta="henkilosto.k6.samaaMielta"
        avo1="henkilosto.mikaHyvin"
        avo2="henkilosto.mitenVoisitParantaa"
        avo3="henkilosto.mitenParantaaYhdessa"
        edellinenSivu="o"
        seuraavaSivu="7"
      />
    ),
  },
  {
    polku: "7",
    element: (
      <KyselySivu
        kysymys="7"
        otsikko="henkilosto.k7.otsikko"
        eriMielta="henkilosto.k7.eriMielta"
        samaaMielta="henkilosto.k7.samaaMielta"
        avo1="henkilosto.mikaHyvin"
        avo2="henkilosto.mitenVoisitParantaa"
        avo3="henkilosto.mitenParantaaYhdessa"
        edellinenSivu="6"
        seuraavaSivu="8"
      />
    ),
  },
  {
    polku: "8",
    element: (
      <KyselySivu
        kysymys="8"
        otsikko="henkilosto.k8.otsikko"
        eriMielta="henkilosto.k8.eriMielta"
        samaaMielta="henkilosto.k8.samaaMielta"
        avo1="henkilosto.mikaHyvin"
        avo2="henkilosto.mitenVoisitParantaa"
        avo3="henkilosto.mitenParantaaYhdessa"
        edellinenSivu="7"
        seuraavaSivu="9"
      />
    ),
  },
  {
    polku: "9",
    element: (
      <KyselySivu
        kysymys="9"
        otsikko="henkilosto.k9.otsikko"
        eriMielta="henkilosto.k9.eriMielta"
        samaaMielta="henkilosto.k9.samaaMielta"
        avo1="henkilosto.mikaHyvin"
        avo2="henkilosto.mitenVoisitParantaa"
        avo3="henkilosto.mitenParantaaYhdessa"
        edellinenSivu="8"
        seuraavaSivu="10"
      />
    ),
  },
  {
    polku: "10",
    element: (
      <KyselySivu
        kysymys="10"
        otsikko="henkilosto.k10.otsikko"
        eriMielta="henkilosto.k10.eriMielta"
        samaaMielta="henkilosto.k10.samaaMielta"
        avo1="henkilosto.perustelusi"
        avo2="henkilosto.mitenVoisitParantaa"
        avo3="henkilosto.mitenParantaaYhdessa"
        edellinenSivu="9"
        seuraavaSivu="taustat"
      />
    ),
  },
  {
    polku: "taustat",
    element: (
      <HenkilostoTaustatietoSivu
        edellinenSivu="10"
        seuraavaSivu="kiitos"
        lahetaData={true}
      />
    ),
  },
  {
    polku: "kiitos",
    element: (
      <KiitosSivu
        otsikko="henkilosto.kiitos.otsikko"
        p1="henkilosto.kiitos.p1"
        naytaNapit={false}
      />
    ),
  },
];
