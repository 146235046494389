import React from "react";
import Typography from "@mui/material/Typography";
import { Theme } from "@mui/material/styles";
import { SxProps } from "@mui/system";

interface YritysnimiProps {
  sx?: SxProps<Theme>;
  yritysnimi: string;
}

const Yritysnimi: React.FC<YritysnimiProps> = ({ sx, yritysnimi }) => {
  return (
    <Typography
      variant="h5"
      sx={{
        ...sx,
        fontStyle: "italic",
        textAlign: "right",
      }}
    >
      {yritysnimi}
    </Typography>
  );
};

export default Yritysnimi;
