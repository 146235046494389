import React from "react";
import { useTranslation } from "react-i18next";
import Flag from "react-world-flags";
import { Box, Button } from "@mui/material";
import { Container } from "@mui/material";

import { useAppState, ApplicationState } from "../www/Unprotected/CommonFormComponents/state";

type StateType = ApplicationState;

const Kielipalkki: React.FC = () => {
  const { i18n } = useTranslation();
  const { setState } = useAppState();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    setState((prevState: StateType) => ({ ...prevState, lng }));
  };

  const kielet = [
    { lang: "fi", flag: "FI" },
    { lang: "se", flag: "SE" },
    { lang: "en", flag: "GB" },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <Container maxWidth="xs">
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            height: "50px",
            width: "100%",
            p: 4,
          }}
        >
          {kielet.map((kieli, index) => (
            <Button
              id={kieli.lang}
              key={index}
              type="button"
              onClick={() => changeLanguage(kieli.lang)}
              sx={{
                border: "none",
                cursor: "pointer",
                margin: "0px",
                height: "30px",
                padding: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Flag
                style={{
                  width: "auto",
                  height: "100%",
                  objectFit: "contain",
                  border: "1px solid lightgrey",
                }}
                code={kieli.flag}
              />
            </Button>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default Kielipalkki;
