import { Box } from "@mui/material";

import NagigationButtons from "../CommonFormComponents/KyselysivunOsat/NavigationButtons";
import TervetuloaTeksti from "./TervetuloaTeksti";
import Kielipalkki from "../../../components/Kielipalkki";

interface TervetuloaSivuProps {
  seuraavaSivu: string;
  buttonSeuraava?: string;
}

const TervetuloaSivu: React.FC<TervetuloaSivuProps> = ({ seuraavaSivu, buttonSeuraava }) => {
  return (
    <Box>
      <Kielipalkki />
      <TervetuloaTeksti />
      <NagigationButtons
        showEdellinen={false}
        buttonSeuraava={buttonSeuraava}
        seuraavaSivu={seuraavaSivu}
      />
    </Box>
  );
};

export default TervetuloaSivu;
