import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import NoSsr from "@mui/material/NoSsr";
import Zoom from "@mui/material/Zoom";
import useMediaQuery from "@mui/material/useMediaQuery";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useTheme } from "@mui/material/styles";
import { Tooltip, Typography } from "@mui/material";

import Header from "./Header";
import Footer from "./Footer";
import Sidebar from "./Sidebar";

interface Props {
  children: React.ReactNode;
}

const Layout = ({ children }: Props): JSX.Element => {
  const theme = useTheme();

  // Piilotetaan Tilaa -huomionappi oikeasta alareunasta jos sivun yläreuna lähestyy (marginaalin verran ennen) Tilaa -osiota
  const marginaali = 400;
  const [isVisible, setIsVisible] = useState(true);
  useEffect(() => {
    const thresholdElement = document.getElementById("tilaa");
    const handleScroll = () => {
      if (thresholdElement && window.pageYOffset > thresholdElement.offsetTop - marginaali) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const isLg = useMediaQuery(theme.breakpoints.up("lg"), { defaultMatches: true });

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = (): void => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = (): void => {
    setOpenSidebar(false);
  };

  const open = isLg ? false : openSidebar;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 10,
  });

  const scrollTo = (id: string): void => {
    setTimeout(() => {
      const element = document.querySelector(`#${id}`) as HTMLElement;
      if (!element) {
        return;
      }
      window.scrollTo({ left: 0, top: element.offsetTop, behavior: "smooth" });
    });
  };

  return (
    <Box
      id="page-top"
      sx={{
        backgroundColor: theme.palette.background.default,
        height: "100%",
        minWidth: 300,
      }}
    >
      <Header
        onSidebarOpen={handleSidebarOpen}
        position="fixed"
      />
      <Sidebar
        onClose={handleSidebarClose}
        open={open}
      />
      <Box
        width={1}
        margin="0 auto"
      >
        {children}
      </Box>
      <Footer />
      <NoSsr>
        <Zoom in={trigger}>
          <Box
            onClick={() => scrollTo("page-top")}
            role="presentation"
            sx={{ position: "fixed", bottom: 24, right: 32 }}
          >
            <Tooltip
              title="Sivun alkuun"
              placement="top"
            >
              <Fab
                color="primary"
                size="small"
                sx={{
                  color: theme.palette.common.black,
                  "&:hover": {
                    backgroundColor: "transparent",
                    color: theme.palette.primary.main,
                    border: "2px solid" + theme.palette.primary.main,
                  },
                }}
              >
                <KeyboardArrowUpIcon />
              </Fab>
            </Tooltip>
          </Box>
        </Zoom>

        {isVisible && (
          <Zoom in={trigger}>
            <Box
              id="tilaa_huomio"
              onClick={() => scrollTo("tilaa")}
              role="presentation"
              sx={{ position: "fixed", bottom: 24, right: 90 }}
            >
              <Tooltip
                title="Tilauslomakkeelle"
                placement="top"
              >
                <Fab
                  variant="extended"
                  color="primary"
                  size="large"
                  aria-label="vieritä tilauslomakkeelle"
                  sx={{
                    color: theme.palette.common.black,
                    "&:hover": {
                      backgroundColor: "transparent",
                      color: theme.palette.primary.main,
                      border: "2px solid" + theme.palette.primary.main,
                    },
                    display: "block",
                    textAlign: "center",
                    height: "5rem",
                    paddingX: "1.5rem",
                  }}
                >
                  <Typography variant="h4">Tilaa</Typography>
                  <Typography>350 € + alv</Typography>
                </Fab>
              </Tooltip>
            </Box>
          </Zoom>
        )}
      </NoSsr>
    </Box>
  );
};

export default Layout;
