import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import KolmeVaihetta from "../Sisalto/KolmeVaihetta";
import OsioOtsikko from "../../../../components/OsioOtsikko";
import Spacer from "../../../../components/Spacer";
interface CustomComponentProps {
  pt?: number;
  pr?: number;
  pb?: number;
  pl?: number;
}

const Toteutustapa: React.FC<CustomComponentProps> = ({ pt = 0, pr = 2, pb = 0, pl = 2 }): JSX.Element => {
  return (
    <div id="toteutus">
      <Spacer />
      <Box sx={{ pt, pr, pb, pl }}>
        <OsioOtsikko otsikko="Toteutustapa" />
        <Container>
          <Box>
            <Grid
              container
              spacing={4}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <KolmeVaihetta
                otsikko0="Ilmoittautuminen"
                kuvaus01="Ilmoittaudu tutkimukseen 19.5.2023 mennessä. Ilmoittautumisen jälkeen saatte vahvistusviestin sähköpostitse."
                otsikko1="Tiedonkeruu"
                kuvaus11="Tutkimuksen tiedonkeruu toteutetaan kokonaan sähköisesti touko-kesäkuun aikana. Toimitamme valmiin kyselykutsun, joka sisältää yrityksenne vastauslinkin. Voitte lähettää viestin niille asiakkaille, joilta haluatte kysyä palautetta. Toimitamme teille selkeät ohjeet Vuosipalautteen käynnistämiseksi."
                kuvaus12="Vastausaikaa tutkimuksessa on noin kolme viikkoa. Kerromme viikon välein vastaajamäärän kehittymisestä."
                otsikko2="Tulokset"
                kuvaus21="Tutkimuksen päätyttyä toimitamme teille 1.8.2023 mennessä kattavan materiaalipaketin, joka sisältää tulosraportit ja viestintämateriaalit."
                kuvaus22="Tulosraportit: Kokonaisraportti on visuaalinen yhteenveto numeerisista tuloksista ja saamistanne avopalautteista. Lisäksi saatte tiivistelmän kokonaiskeskiarvosta sekä suositteluindeksistä."
                kuvaus23="Viestintäpaketti: Vuosipalaute sisältää materiaalia, joka auttaa viestimään yrityksenne asiakeskeisyydestä. Päätöksen materiaalien käytöstä, käyttökohteista teette itsenäisesti."
              />
            </Grid>
          </Box>
        </Container>
      </Box>
    </div>
  );
};

export default Toteutustapa;
