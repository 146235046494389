import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

import HeroButtons from "./HeroButtons";

const Hero = (): JSX.Element => {
  const theme = useTheme();
  return (
    <div id="home">
      <Box sx={{ pt: 10, pb: 13, px: 2, backgroundColor: theme.palette.background.paper }}>
        <Container
          maxWidth="md"
          sx={{
            mt: "80px",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box marginBottom={2}>
            <Typography
              variant="h5"
              component="p"
              color={theme.palette.text.secondary}
              sx={{
                fontWeight: 400,
                color: "#22D7C1",
                mb: 3,
                maxWidth: 800,
              }}
              align="center"
            >
              Haluatko selvittää helposti yrityksesi asiakastyytyväisyyden tason suhteessa toimialan keskiarvoon?
            </Typography>
            <Typography
              align="center"
              color={theme.palette.text.primary}
              variant="h3"
              sx={{ fontWeight: 700 }}
              gutterBottom
            >
              Kiinteistöpalvelujen vuosipalaute 2023
            </Typography>
          </Box>
          <Box marginBottom={3}>
            <Typography
              variant="h5"
              component="p"
              color={theme.palette.text.secondary}
              sx={{ fontWeight: 400, marginTop: 2 }}
              align="center"
            >
              -tutkimuksen avulla selvität helposti asiakkaiden tyytyväisyyden ja odotukset. Samalla se on hyvä ja
              edullinen tapa hoitaa asiakassuhteita, sekä auttaa yritystäsi viestimään asiakaskeskeisyydestä.
            </Typography>
          </Box>
          <HeroButtons />
        </Container>
      </Box>
    </div>
  );
};

export default Hero;
