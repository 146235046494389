import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import OsioOtsikko from "../../../../components/OsioOtsikko";
import Perustelu from "./Perustelu";
import Spacer from "../../../../components/Spacer";

interface CustomComponentProps {
  pt?: number;
  pr?: number;
  pb?: number;
  pl?: number;
}

const Miksi: React.FC<CustomComponentProps> = ({ pt = 0, pr = 2, pb = 0, pl = 2 }): JSX.Element => {
  return (
    <div id="miksi">
      <Spacer />
      <Box sx={{ pt, pr, pb, pl }}>
        <OsioOtsikko otsikko="Miksi osallistua?" />
        <Container>
          <Grid
            container
            spacing={4}
          >
            <Perustelu
              otsikko="VASTUULLINEN"
              ikoni="autorenew"
              kuvaus="Palautteen kysyminen on tapa osoittaa toiminnan läpinäkyvyyttä. Se välittää avointa ja luottamusta herättävää toimintatapaa."
            />
            <Perustelu
              otsikko="VERTAILEVA"
              ikoni="equalizer"
              kuvaus="Yritys saa tietoa toiminnan vahvuuksista ja kehityskohteista, myös suhteessa muihin kiinteistöpalvelualan yrityksiin."
            />
            <Perustelu
              otsikko="POSITIIVINEN"
              ikoni="add"
              kuvaus="Asiakkaitasi pyydetään kertomaan, mikä on ollut hyvää toiminnassa ja miten sitä voisi parantaa entisestään. Positiivisuus kehittää liiketoimintaa!"
            />
            <Perustelu
              otsikko="EDULLINEN"
              ikoni="savings"
              kuvaus="Vuosipalautteen hinta 350 € + alv. Samaan aikaan voi tehdä sekä asiakas- että henkilöstötutkimuksen."
            />
            <Perustelu
              otsikko="MYYVÄ"
              ikoni="euro"
              kuvaus="Potentiaaliset asiakkaat haluavat asiakaskeskeisen kumppanin. Vuosipalautetta kannattaa hyödyntää myynnissä ja markkinoinnissa."
            />
            <Perustelu
              otsikko="VIESTINNÄLLINEN"
              ikoni="cell_tower"
              kuvaus="Auttaa kommunikoimaan yrityksesi asiakaskeskeisyydestä. Yritys saa valmista graafista materiaalia niin verkkosivuille kuin esim. tarjousdokumentteihin."
            />
          </Grid>
        </Container>
      </Box>
    </div>
  );
};

export default Miksi;
