import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import Osa from "./Osa";
import OsaBulleteilla from "./OsaBulleteilla";
import raporttikuva from "../../../../assets/images/tuloskuva.svg";
import lomakeMock from "../../../../assets/images/lomakekuva.png";
import someEsim from "../../../../assets/images/someEsim.jpeg";
import OsioOtsikko from "../../../../components/OsioOtsikko";
import Spacer from "../../../../components/Spacer";
interface CustomComponentProps {
  pt?: number;
  pr?: number;
  pb?: number;
  pl?: number;
}

const Sisalto: React.FC<CustomComponentProps> = ({ pt = 0, pr = 2, pb = 0, pl = 2 }): JSX.Element => {
  return (
    <div id="sisalto">
      <Spacer />
      <Box sx={{ pt, pr, pb, pl }}>
        <Container>
          <OsioOtsikko otsikko="Sisältö" />
          <Grid
            container
            spacing={2}
          >
            <Osa
              otsikko="Kysely"
              kuvaus="Asiakastyytyväisyyttä selvitetään viidellä teemalla: Yhteydenpidon helppous, Lupausten pitäminen, Asiakkaasta välittäminen, Erottuminen kilpailijoista, sekä Suositteluhalukkuus. Lisäksi kysytään muutama taustakysymys vastaajasta. Kyselyyn voi vastata suomeksi, ruotsiksi ja englanniksi."
              kuva={lomakeMock}
              alt="Kyselylomake-esimerkki"
            />
            <Osa
              otsikko="Tulosraportti"
              kuvaus="Selkeät, helppolukuiset raportit. Yrityksenne tuloksen rinnalla esitetään vertailuaineisto muihin alan yrityksiin. Vastaajan taustatietoja ja yrityksen taustatietoja käytetään hyödyksi raportissa."
              kuva={raporttikuva}
              alt="Tulosraportti-esimerkki"
            />
            <OsaBulleteilla
              otsikko="Viestintäpaketti"
              bullet_1="Tunnus siitä, että yrityksenne seuraa asiakastyytyväisyyttä ja haluaa kehittää palvelua asiakaspalautteen perusteella"
              bullet_2="Kokonaistulos ja NPS-suositteluindeksi -kuvat"
              bullet_3="Asiakkaiden kirjoittamia positiivisia palautteita viestinnässä käytettäväksi"
              kuva={someEsim}
              alt="Viestintäpaketti-esimerkki"
            />
          </Grid>
        </Container>
      </Box>
    </div>
  );
};

export default Sisalto;
