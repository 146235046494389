import React from "react";

import Box from "@mui/material/Box";
import { Container } from "@mui/material";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import { useTranslation } from "react-i18next";

import { useAppState } from "../CommonFormComponents/state";

import TervetuloaKappale from "../CommonFormComponents/Tervetuloa/TervetuloaKappale";

interface TervetuloaTekstiProps {
  sx?: SxProps<Theme>;
}

const TervetuloaTeksti: React.FC<TervetuloaTekstiProps> = ({ sx }) => {
  const { t } = useTranslation();
  const { state } = useAppState();
  return (
    <Container
      sx={{
        margin: "1rem 0",
      }}
    >
      <Box>
        <Typography
          variant="h3"
          sx={{
            textAlign: "left",
            marginY: 2,
          }}
        >
          {t("henkilosto.tervetuloa.otsikko")}
        </Typography>
        <Typography
          variant="h5"
          sx={{
            textAlign: "left",
          }}
        >
          {t("henkilosto.tervetuloa.p1", { yritysNimi: state.yritysNimi })}
        </Typography>
        <TervetuloaKappale>{t("henkilosto.tervetuloa.p2")}</TervetuloaKappale>
        <TervetuloaKappale>
          {t("henkilosto.tervetuloa.p3.1")}
          <span style={{ fontWeight: 900 }}>{t("henkilosto.tervetuloa.p3.2")}</span>
          {t("henkilosto.tervetuloa.p3.3")}
        </TervetuloaKappale>
      </Box>
    </Container>
  );
};

export default TervetuloaTeksti;
