import React from "react";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import { alpha, useTheme } from "@mui/material/styles";
import LogoutButton from "./LogoutButton";

interface Props {
  onSidebarOpen: () => void;
  position?: "fixed" | "absolute" | "sticky" | "static" | "relative";
}

const ProtectedHeader = ({ onSidebarOpen, position = "sticky" }: Props): JSX.Element => {
  const theme = useTheme();

  return (
    <>
      <AppBar
        color="transparent"
        position={position}
        sx={{
          backgroundColor: "#545759",
          backgroundImage: null,
          border: 0,
          padding: "0px 0",
          paddingBottom: "5px",
          marginBottom: "5px",
          top: "auto",
          boxShadow: "0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)",
        }}
      >
        <Toolbar sx={{ minHeight: 70 }}>
          <Link
            href="#home"
            sx={{ textDecoration: "none" }}
          >
            <IconButton
              size="large"
              disabled
            >
              <Box
                sx={{
                  display: { xs: "inline" },
                  "@media (max-width: 299px)": {
                    display: "none",
                  },
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    flexGrow: 1,
                    color: theme.palette.text.primary,
                    fontWeight: "bold",
                    textTransform: "uppercase",
                    textDecoration: "none",
                    marginLeft: "10px",
                  }}
                >
                  <img
                    width={200}
                    alt="Toimialadata"
                    src="https://toimialadata.fi/st/kuvat/toimialadata-logo-valkoinen.png?230321-104905"
                  />
                </Typography>
              </Box>
            </IconButton>
          </Link>
          <Box sx={{ flexGrow: 1 }} />
          <Box
            sx={{
              alignItems: "center",
              display: { lg: "flex", md: "none", xs: "none" },
            }}
          >
            {/* TÄHÄN YLÄPALKIN LINKIT */}
            <LogoutButton />
          </Box>
          <Box
            sx={{
              display: { md: "block", lg: "none" },
            }}
            alignItems="center"
          >
            <Button
              onClick={() => onSidebarOpen()}
              aria-label="Menu"
              variant="outlined"
              sx={{
                borderRadius: 0,
                minWidth: "auto",
                padding: 1,
                borderColor: alpha(theme.palette.divider, 0.2),
              }}
            >
              <MenuIcon />
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default ProtectedHeader;
