import React from "react";
import { BrowserRouter, Outlet, Routes, Route } from "react-router-dom";
import { HelmetProvider, Helmet } from "react-helmet-async";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";

// GOOGLE ANALYTICS:---------------------------------------------------
import AnalyticsTracker from "./AnalyticsTracker";

// PROTECTED:----------------------------------------------------------
import ProtectedLayout from "./pages/www/Protected/layout/ProtectedLayout";
import Tilaajataulu from "./pages/www/Protected/Tilaajataulu/Tilaajataulu";

import AuthProvider from "./pages/www/Unprotected/Login/AuthContext";
import ProtectedRoute from "./pages/www/Unprotected/Login/ProtectedRoute";

// UNPROTECTED:----------------------------------------------------------
import { AppProvider } from "./pages/www/Unprotected/CommonFormComponents/state";
import SuspenseWrapper from "./SuspenseWrapper";

import { Asiakaskyselyrakenne } from "./pages/www/Unprotected/Asiakaskysely/Asiakaskyselyrakenne";
import { Henkilostokyselyrakenne } from "./pages/www/Unprotected/Henkilostokysely/Henkilostokyselyrakenne";
import Home from "./pages/www/Unprotected/Home/Home";
import Kysely from "./pages/www/Unprotected/CommonFormComponents/Kysely";
import Login from "./pages/www/Unprotected/Login/Login";

// TYYLIT:----------------------------------------------------------
import getTheme from "./pages/theme/theme";
import Layout from "./pages/www/Unprotected/layout/Layout";

// 40x, 50x ja muut Errorit:----------------------------------------------------------
import Error401Unauthorized from "./pages/www/Unprotected/Error401Unauthorized";
import Error404PageNotFound from "./pages/www/Unprotected/Error404PageNotFound";
import Error500InternalServerError from "./pages/www/Unprotected/Error500InternalServerError";
import ErrorKyselySuljettu from "./pages/www/Unprotected/ErrorKyselySuljettu";

const App = (): JSX.Element => {
  return (
    <SuspenseWrapper>
      <HelmetProvider>
        <Helmet
          titleTemplate="Kiinteistöpalvelujen vuosipalaute 2023"
          defaultTitle="Kiinteistöpalvelujen vuosipalaute 2023"
        />
        <ThemeProvider theme={getTheme()}>
          <CssBaseline />
          <AuthProvider>
            <BrowserRouter>
              <AnalyticsTracker />
              <Routes>
                <Route
                  path="/"
                  element={
                    <Layout>
                      <Home />
                    </Layout>
                  }
                />
                <Route
                  path="/401"
                  element={<Error401Unauthorized />}
                />
                <Route
                  path="/404"
                  element={<Error404PageNotFound />}
                />
                <Route
                  path="/500"
                  element={<Error500InternalServerError />}
                />
                <Route
                  path="/kysely-suljettu"
                  element={<ErrorKyselySuljettu />}
                />
                <Route
                  path="/kirjautuminen"
                  element={<Login />}
                />
                <Route
                  path="/asiakaskysely/:identifier/"
                  element={
                    <>
                      <AppProvider formId="asiakaskysely">
                        <Kysely>
                          <Outlet />
                        </Kysely>
                      </AppProvider>
                    </>
                  }
                >
                  {Object.values(Asiakaskyselyrakenne).map(({ polku, element }) => (
                    <Route
                      key={polku}
                      path={polku}
                      element={element}
                    />
                  ))}
                </Route>
                <Route
                  path="/henkilostokysely/:identifier/"
                  element={
                    <>
                      <AppProvider formId="henkilostokysely">
                        <Kysely>
                          <Outlet />
                        </Kysely>
                      </AppProvider>
                    </>
                  }
                >
                  {Object.values(Henkilostokyselyrakenne).map(({ polku, element }) => (
                    <Route
                      key={polku}
                      path={polku}
                      element={element}
                    />
                  ))}
                </Route>
                <Route
                  path="/pr/*"
                  element={
                    <ProtectedLayout>
                      <ProtectedRoute />
                    </ProtectedLayout>
                  }
                >
                  <Route
                    path="tilaajataulu"
                    element={<Tilaajataulu />}
                  />
                </Route>
              </Routes>
            </BrowserRouter>
          </AuthProvider>
        </ThemeProvider>
      </HelmetProvider>
    </SuspenseWrapper>
  );
};

export default App;
