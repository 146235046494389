import React from 'react';
import Tekstikappale from './Tekstikappale';
import { Theme } from '@mui/material/styles';
import Valiotsikko from './Valiotsikko';

interface ValiotsikkoJaTekstikappaleetProps {
  otsikko: string;
  kuvaus_1: string;
  kuvaus_2?: string;
  kuvaus_3?: string;
  theme: Theme;
}

const ValiotsikkoJaTekstikappaleet: React.FC<ValiotsikkoJaTekstikappaleetProps> = ({
  otsikko,
  kuvaus_1,
  kuvaus_2,
  kuvaus_3,
  theme,
}) => {
  return (
    <>
      <Valiotsikko otsikko={otsikko} />

      <Tekstikappale
        kuvaus={kuvaus_1}
        theme={theme}
      />

      {kuvaus_2 && (
        <Tekstikappale
          sx={{ marginTop: 1 }}
          kuvaus={kuvaus_2}
          theme={theme}
        />
      )}

      {kuvaus_3 && (
        <Tekstikappale
          sx={{ marginTop: 1 }}
          kuvaus={kuvaus_3}
          theme={theme}
        />
      )}
    </>
  );
};

export default ValiotsikkoJaTekstikappaleet;
