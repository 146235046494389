import React from "react";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";

interface Props {
  href: string;
  text: string;
}

const CustomButton = ({ href, text }: Props): JSX.Element => {
  const theme = useTheme();

  return (
    <Button
      component="a"
      color="primary"
      href={href}
      variant="text"
      sx={{
        justifyContent: "left",
        alignItems: "flex-start",
        minWidth: "100px",
        maxWidth: "100px",
        minHeight: "65px",
        maxHeight: "65px",
        color: theme.palette.text.primary,
        textTransform: "uppercase",
        marginLeft: "25px",
        "&:active": {
          color: theme.palette.primary.main,
        },
        "&:hover": {
          color: theme.palette.primary.main,
        },
      }}
    >
      {text}
    </Button>
  );
};

export default CustomButton;
