import React from "react";
import { Theme } from "@mui/material/styles";
import { SxProps } from "@mui/system";

import NagigationButtons from "../KyselysivunOsat/NavigationButtons";
import KiitosTeksti from "./KiitosTeksti";

interface KiitosSivuProps {
  sx?: SxProps<Theme>;
  otsikko: string;
  p1: string;
  showEdellinen?: boolean;
  naytaNapit?: boolean;
}

const KiitosSivu: React.FC<KiitosSivuProps> = ({ sx, otsikko, p1, showEdellinen, naytaNapit = true }) => {
  return (
    <>
      <KiitosTeksti
        otsikko={otsikko}
        p1={p1}
      />
      {naytaNapit && <NagigationButtons showEdellinen={showEdellinen} />}
    </>
  );
};

export default KiitosSivu;
