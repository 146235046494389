import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import Hlosto from "../Sisalto/Hlosto";
import OsioOtsikko from "../../../../components/OsioOtsikko";
import Spacer from "../../../../components/Spacer";
interface CustomComponentProps {
  pt?: number;
  pr?: number;
  pb?: number;
  pl?: number;
}

const Henkilosto: React.FC<CustomComponentProps> = ({ pt = 0, pr = 2, pb = 0, pl = 2 }): JSX.Element => {
  return (
    <div id="hlosto">
      <Spacer />
      <Box sx={{ pt, pr, pb, pl }}>
        <OsioOtsikko otsikko="Henkilöstö" />
        <Container maxWidth="md">
          <Hlosto
            otsikko1="VUOSIPALAUTE - Henkilöstötutkimus"
            kuvaus1="Suosittelemme myös sisäistä tutkimusta!"
            kuvaus21=" Oma henkilöstönne arvioi toimintanne asiakaskeskeisyyttä sekä organisaationne toimintaa yhteensä 10 kysymyksellä. Asiakaskeskeisyyden lisäksi teemoina ovat. sisäinen yhteistyö, ilmapiiri,  esihenkilötyö sekä työpaikan suosittelu."
            kuvaus22="Arvosanan lisäksi vastaajalta pyydetään avointa palautetta: mikä kysyttävissä teemoissa on hyvin, miten vastaaja itse voisi parantaa tilannetta ja miten myönteistä kehitystä voitaisiin tukea muuten. On tärkeää, että sisäinen tutkimus tuottaa tietoa myös toimivista asioista,"
            kuvaus23="Sisältää kattavan raporttipaketin, jossa yritystenne tulosta verrataan toimialan keskiarvoon. Toimitamme myös viestintämateriaalia, jonka avulla on helppo kertoa toimintanne avoimuudesta ja vastuullisuudesta esim. verkkosivuilla tai tarjouksissa."
          />
        </Container>
      </Box>
    </div>
  );
};

export default Henkilosto;
