import React, { createContext, useState, useContext, useEffect } from "react";
import axiosInstance from "./axiosInstance";

interface AuthContextData {
  isAuthenticated: boolean;
  login: (tokens: { access_token: string; refresh_token: string }) => void;
  logout: () => void;
  refreshToken: () => Promise<void>;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const useAuth = () => {
  return useContext(AuthContext);
};

interface AuthProviderProps {
  children: React.ReactNode;
}
export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  // const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  const login = async (tokens: { access_token: string; refresh_token: string }) => {
    try {
      localStorage.setItem("access_token", tokens.access_token);
      localStorage.setItem("refresh_token", tokens.refresh_token);
      setIsAuthenticated(true);
    } catch (error) {
      console.error("Error setting the tokens and updating isAuthenticated state:", error);
    }
  };

  const refreshToken = async () => {
    try {
      const refresh_token = localStorage.getItem("refresh_token");
      if (!refresh_token) {
        throw new Error("No refresh token found in localStorage.");
      }
      const response = await axiosInstance.post("/auth/token/refresh/", {
        refresh: refresh_token,
      });
      const { access_token, new_refresh_token } = response.data; // update the variable name to avoid conflict
      localStorage.setItem("access_token", access_token);
      localStorage.setItem("refresh_token", new_refresh_token);
    } catch (error) {
      console.error("Error refreshing token:", error);
      logout();
    }
  };

  const logout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    setIsAuthenticated(false);
  };

  useEffect(() => {
    const access_token = localStorage.getItem("access_token");
    const refresh_token = localStorage.getItem("refresh_token");
    if (access_token && refresh_token) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  if (isAuthenticated === null) {
    return <></>;
  }

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, refreshToken }}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
