import React from "react";
import { Theme } from "@mui/material/styles";
import { SxProps } from "@mui/system";
import { Box } from "@mui/material";

import NagigationButtons from "./NavigationButtons";
import Otsikko from "./Otsikko";
import PaakysymysOsio from "./PaakysymysOsio";
import Yritysnimi from "./Yritysnimi";

import { useKyselyHandleChange } from "../useHandleChange";
import { useAppState } from "../state";

export interface KyselySivuProps {
  sx?: SxProps<Theme>;
  otsikko: string;
  seuraavaSivu: string;
  edellinenSivu?: string;
  showEdellinen?: boolean;
  kysymys?: string;
  eriMielta?: string;
  samaaMielta?: string;
  naytaNapit?: boolean;
  avo1?: string | undefined | null;
  avo2?: string | undefined | null;
  avo3?: string | undefined | null;
}

const KyselySivu: React.FC<KyselySivuProps> = ({
  sx,
  otsikko,
  seuraavaSivu,
  edellinenSivu,
  showEdellinen = true,
  kysymys,
  eriMielta = "Täysin eri mieltä",
  samaaMielta = "Täysin samaa mieltä",
  naytaNapit = true,
  avo1 = "mikaOllutHyvaa",
  avo2 = "mitenToimiaParemmin",
  avo3 = "mika...",
}) => {
  const name_q = `q${kysymys}`;
  const name_a1 = `q${kysymys}_a1`;
  const name_a2 = `q${kysymys}_a2`;
  const name_a3 = `q${kysymys}_a3`;
  const name_eos = `q${kysymys}_eos`;

  const { state } = useAppState();
  const handleChange = useKyselyHandleChange();

  return (
    <Box>
      <Yritysnimi yritysnimi={state.yritysNimi || ""} />
      <Otsikko otsikko={otsikko} />
      {kysymys && (
        <PaakysymysOsio
          name_q={name_q}
          name_a1={name_a1}
          name_a2={name_a2}
          name_a3={name_a3}
          name_eos={name_eos}
          eriMielta={eriMielta}
          samaaMielta={samaaMielta}
          handleChange={handleChange}
          handleCheckboxChange={handleChange}
          avo1={avo1}
          avo2={avo2}
          avo3={avo3}
        />
      )}

      {naytaNapit && (
        <NagigationButtons
          edellinenSivu={edellinenSivu}
          showEdellinen={showEdellinen}
          seuraavaSivu={seuraavaSivu}
        />
      )}
    </Box>
  );
};

export default KyselySivu;
