import { ReactNode, useEffect } from "react";
import axios, { AxiosError } from "axios";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useAppState } from "./state";

import KyselyPaper from "../../../components/KyselyPaper";
import { ApplicationState } from "./state";

import config from "../../../../config";

interface KyselyProps {
  children: ReactNode;
}

type StateType = ApplicationState;

function Kysely(props: KyselyProps) {
  const { identifier } = useParams();
  const { setState } = useAppState();
  const navigate = useNavigate();
  const location = useLocation();

  const apiUrl = `${config.baseApiUrl}/yritys/${identifier}`;
  const kyselyStatusApiUrl = `${config.baseApiUrl}/kysely_status/${identifier}/`;

  useEffect(() => {
    async function fetchYritysNimiAndKyselyStatus() {
      try {
        const response = await axios.get(apiUrl, {
          headers: {
            "X-Secret-Key": "hirmuMyrkyllinenKyy",
          },
        });

        const kyselyStatusResponse = await axios.get(kyselyStatusApiUrl, {
          headers: {
            "X-Secret-Key": "hirmuMyrkyllinenKyy",
          },
        });

        const kyselyStatus = kyselyStatusResponse.data;
        if (
          (location.pathname.includes("asiakaskysely") && !kyselyStatus.is_asty_open) ||
          (location.pathname.includes("henkilostokysely") && !kyselyStatus.is_hr_open)
        ) {
          navigate("/kysely-suljettu"); // redirect if kysely is not open
          return; // stop execution if kysely is not open
        }

        let yrityksen_nimi;
        if (response.data.markkinointi_nimi) {
          yrityksen_nimi = response.data.markkinointi_nimi;
        } else {
          yrityksen_nimi = response.data.yrityksen_nimi;
        }
        return yrityksen_nimi;
      } catch (error) {
        console.error("There has been a problem with your fetch operation:", error);
        const axiosError = error as AxiosError;
        if (axiosError.response && axiosError.response.data) {
          console.log("Error data:", axiosError.response.data);
        }
        if (error instanceof Error) {
          console.log("Error:", error.message);
        } else {
          console.log("Error.");
        }
        navigate("/404");
      }
    }

    (async () => {
      const yritysNimi = await fetchYritysNimiAndKyselyStatus();
      if (yritysNimi) {
        setState((prevState: StateType) => ({ ...prevState, yritysNimi, identifier }));
      }
    })();
  }, [identifier, apiUrl, setState, navigate]);

  const { children } = props;
  return <KyselyPaper>{children}</KyselyPaper>;
}

export default Kysely;
