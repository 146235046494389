import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Container, Typography, TextField } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { AxiosError } from "axios";

import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import CustomButton from "../../../components/FormComponents/CustomButton";
import { useAuth } from "./AuthContext";
import axiosInstance from "./axiosInstance";

interface LoginFormInputs {
  email: string;
  password: string;
}
const schema = yup
  .object<LoginFormInputs>({
    email: yup.string().email().required("Tämä on pakollinen tieto."),
    password: yup.string().required("Tämä on pakollinen tieto."),
  })
  .required();

const Login: React.FC = () => {
  const [loginError, setLoginError] = useState<string | null>(null);
  const { login } = useAuth();
  const theme = useTheme();
  const navigate = useNavigate();
  const formMethods = useForm<LoginFormInputs>({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver(schema),
  });

  const { handleSubmit, control } = formMethods;

  function getCookie(name: string) {
    const value = "; " + document.cookie;
    const parts = value.split("; " + name + "=");
    return parts.length === 2 ? parts.pop()?.split(";").shift() ?? "" : "";
  }

  const csrftoken = getCookie("csrftoken");
  // Custom type guard to check if the error is an AxiosError
  function isAxiosError(error: unknown): error is AxiosError {
    return (error as AxiosError).isAxiosError !== undefined;
  }

  const onSubmit = async (data: LoginFormInputs) => {
    try {
      const response = await axiosInstance.post("/auth/login/", data, {
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken,
        },
      });

      const { access_token, refresh_token } = response.data;
      if (access_token && refresh_token) {
        login({ access_token, refresh_token });
        setTimeout(() => {
          navigate("/pr/tilaajataulu");
        }, 100);
      } else {
        console.log("Login failed");
        setLoginError("Login failed");
        navigate("/401");
      }
    } catch (error) {
      console.error("Error logging in:", error);

      if (isAxiosError(error)) {
        if (error.response) {
          if (error.response.status === 401 || error.response.status === 400) {
            setLoginError("Error logging in: Invalid credentials");
            navigate("/401");
          } else {
            setLoginError("Error logging in: " + error.response.statusText);
            navigate("/500");
          }
        } else if (error.request) {
          setLoginError("Error logging in: No response from server");
          navigate("/500");
        } else {
          setLoginError("Error logging in: " + error.message);
          navigate("/500");
        }
      } else {
        // If the error is not an AxiosError
        setLoginError("Error logging in.");
        navigate("/500");
      }
    }
  };

  return (
    <div id="login">
      <Box
        sx={{
          backgroundColor: theme.palette.background.paper,
          minHeight: "80vh",
          pt: 5,
        }}
      >
        <Container maxWidth="xs">
          {loginError && (
            <Typography
              color="error"
              variant="body1"
              sx={{ mb: 2 }}
            >
              {loginError}
            </Typography>
          )}
          <Typography
            color="textPrimary"
            variant="h4"
            align="center"
            sx={{
              mt: 1,
              mb: 2,
            }}
          >
            Kirjautuminen
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Sähköposti"
                  fullWidth
                  required
                  margin="normal"
                  variant="filled"
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Salasana"
                  fullWidth
                  required
                  margin="normal"
                  variant="filled"
                  type="password"
                />
              )}
            />
            <CustomButton
              type="submit"
              buttonText="Käy sisään"
              sx={{ width: "100%", mt: 2 }}
            />
          </form>
        </Container>
      </Box>
    </div>
  );
};

export default Login;
