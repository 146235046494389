import React from "react";
import { ReactNode } from "react";
import { TextField } from "@mui/material";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";
import { useFormContext } from "./FormContext";

type RHFTextFieldProps = {
  name: string;
  label: string;
  onBlur?: () => void;
  disabled?: boolean;
  id?: string;
  value?: string | number;
  sx?: SxProps<Theme>;
  fullWidth?: boolean;
  required?: boolean;
  multiline?: boolean;
  defaultHelperText?: ReactNode; // mahdollistaa normaalin tekstin syöttämisen defaultHelperText='esim näin' lisäksi pitkän tekstin syöttämisen rivitetysti defaultHelperText={`esim näin`} defaultHelperText={<div>esim näin</div>}
  rawHelperText?: string;
  requiredErrorMessage?: string;
};

const RHFTextField: React.FC<RHFTextFieldProps> = ({
  name,
  label,
  onBlur,
  disabled,
  id,
  value,
  sx,
  fullWidth = true,
  required = true,
  multiline = false,
  defaultHelperText,
  requiredErrorMessage = "Tämä kenttä vaaditaan",
}) => {
  const { register, errors } = useFormContext();
  const error = errors[name] ? true : false;
  const helperText = errors[name] ? errors[name].message : defaultHelperText;

  return (
    <TextField
      {...register(name, { required: required ? requiredErrorMessage : false })}
      disabled={disabled}
      id={id}
      label={label}
      variant="filled"
      sx={{ my: 1, ...sx }}
      fullWidth={fullWidth}
      required={required}
      multiline={multiline}
      onBlur={onBlur}
      error={error}
      helperText={helperText}
      value={value}
    />
  );
};

export default RHFTextField;
