import React from "react";
import Typography from "@mui/material/Typography";
import { Theme } from "@mui/material/styles";
import { SxProps } from "@mui/system";
import checkbox from "../../../../assets/images/checkbox.png";
import toimialadataLogoPunainen from "../../../../assets/images/toimialadata-logo-punainen.png";

import { useTranslation } from "react-i18next";

interface KiitosTekstiProps {
  sx?: SxProps<Theme>;
  otsikko: string;
  p1: string;
}

const KiitosTeksti: React.FC<KiitosTekstiProps> = ({ sx, otsikko, p1 }) => {
  const { t } = useTranslation();
  return (
    <>
      <div
        style={{
          position: "relative",
          height: "150px",
          marginBottom: 4,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={checkbox}
          alt="Kiitos"
          style={{
            position: "absolute",
            top: "0%",
            left: "50%",
            transform: "translate(-50%, 0%)",
            maxHeight: "100%",
            maxWidth: "100%",
            borderRadius: 8,
            marginTop: "20px",
          }}
        />
      </div>
      <Typography
        variant="h3"
        sx={{
          ...sx,
          fontWeight: 400,
          lineHeight: 1.5,
          textAlign: "center",
          margin: "3rem 0",
        }}
      >
        {t(otsikko)}
      </Typography>
      <Typography
        variant="h5"
        sx={{
          ...sx,
          fontWeight: 400,
          lineHeight: 1.5,
          textAlign: "center",
          margin: "1rem 0",
        }}
      >
        {t(p1) + " "}
        <a href="https://toimialadata.fi">
          <img
            src={toimialadataLogoPunainen}
            alt="Toimialadata"
            style={{
              height: "1.1rem",
            }}
          />
        </a>
      </Typography>
    </>
  );
};

export default KiitosTeksti;
