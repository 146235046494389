import React from "react";
import { Helmet } from "react-helmet-async";

import Henkilosto from "./Henkilosto/Henkilosto";
import Hero from "./Hero/Hero";
import Hinta from "./Hinta/Hinta";
import Meista from "./Meista/Meista";
import Miksi from "./Miksi/Miksi";
import Sisalto from "./Sisalto/Sisalto";
import Tilaa from "./Tilaa/Tilaa";
import Toteutustapa from "./Toteutustapa/Toteutustapa";

const Home = (): JSX.Element => {
  return (
    <>
      <Helmet>
        <title>Kiinteistöpalvelujen Vuosipalaute 2023</title>
        <meta
          name="description"
          content="Osallistu Kiinteistöpalvelujen Vuosipalaute 2023 -tutkimukseen ja selvitä yrityksesi asiakastyytyväisyyden taso suhteessa toimialan keskiarvoon. Vertaile yritystäsi alan muihin yrityksiin, kehitä asiakassuhteita ja viesti asiakaskeskeisyydestä."
        />
        <meta
          name="keywords"
          content="kiinteistöpalvelut, kiinteistöhuolto, vuosipalaute, asiakastyytyväisyys, vertailututkimus, toimialadata, asiakaspalaute, asiakaskeskeisyys, henkilöstötutkimus, toimialan keskiarvo, asiakaskokemus, henkilöstökokemus, kiinteistöpalvelujen vuosipalaute"
        />
        <meta
          name="robots"
          content="index, follow"
        />
      </Helmet>
      <div id="home">
        <Hero />
        <Miksi />
        <Toteutustapa />
        <Sisalto />
        <Henkilosto />
        <Hinta />
        <Meista />
        <Tilaa />
      </div>
    </>
  );
};

export default Home;
