import React from "react";
import Typography from "@mui/material/Typography";
import { Theme } from "@mui/material/styles";
import { SxProps } from "@mui/system";

import { useTranslation } from "react-i18next";

interface OtsikkoProps {
  sx?: SxProps<Theme>;
  otsikko: string;
}

const Otsikko: React.FC<OtsikkoProps> = ({ sx, otsikko }) => {
  const { t } = useTranslation();
  return (
    <Typography
      sx={{
        ...sx,
        textAlign: "left",
        fontWeight: 500,
        fontSize: "1.85rem",
        width: "100%",
      }}
    >
      {t(otsikko)}
    </Typography>
  );
};

export default Otsikko;
