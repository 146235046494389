import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

interface CustomComponentProps {
  otsikko1: string;
  kuvaus1: string;
  kuvaus21: string;
  kuvaus22: string;
  kuvaus23: string;
}

const Hlosto: React.FC<CustomComponentProps> = ({ otsikko1, kuvaus1, kuvaus21, kuvaus22, kuvaus23 }) => {
  const theme = useTheme();
  return (
    <Grid
      item
      md={8}
      xs={12}
    >
      <Box
        sx={{
          padding: 2,
          margin: 0,
        }}
        component={Card}
        height={1}
        display="flex"
        flexDirection="column"
        boxShadow={0}
      >
        <CardContent>
          <Box marginBottom={0}>
            <Typography
              variant="subtitle1"
              gutterBottom
              sx={{
                fontWeight: 500,
                fontSize: '1.25rem',
                alignItems: 'center',
                mb: 0,
              }}
            >
              <span style={{ textTransform: 'uppercase' }}>{otsikko1}</span>
            </Typography>
            <Typography
              sx={{
                marginTop: 1,
                color: '#22D7C1',
                fontStyle: 'italic',
              }}
              variant="subtitle1"
              marginTop={theme.spacing(1)}
              color={theme.palette.text.secondary}
            >
              {kuvaus1}
            </Typography>
            <Typography
              sx={{
                marginTop: 1,
              }}
              variant="subtitle1"
              marginTop={theme.spacing(1)}
              color={theme.palette.text.secondary}
            >
              {kuvaus21}
            </Typography>
            <Typography
              sx={{
                marginTop: 1,
              }}
              variant="subtitle1"
              marginTop={theme.spacing(1)}
              color={theme.palette.text.secondary}
            >
              {kuvaus22}
            </Typography>
            <Typography
              sx={{
                marginTop: 1,
              }}
              variant="subtitle1"
              marginTop={theme.spacing(1)}
              color={theme.palette.text.secondary}
            >
              {kuvaus23}
            </Typography>
          </Box>
        </CardContent>
      </Box>
    </Grid>
  );
};

export default Hlosto;
