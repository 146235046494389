import React from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useState, useEffect } from "react";
import axiosInstance from "../../Unprotected/Login/axiosInstance";
import { useAuth } from "../../Unprotected/Login/AuthContext";

interface TilaajaData {
  name: string;
  yrityksen_nimi: string;
  y_tunnus: string;
  markkinointi_nimi: string;
  formatted_paatoimiala: string;
  formatted_kokoluokka: string;
  yrityksen_postinumero: string;
  tilaajan_nimi: string;
  yhteys_sposti: string;
  yhteys_puh: string;
  formatted_tilatut_tuotteet: string[];
  muuta: string;
  pvm: string;
}

const Tilaajataulu: React.FC = () => {
  const [data, setData] = useState<TilaajaData[]>([]);
  const [error, setError] = useState<null | string>(null);
  const theme = useTheme();
  const { refreshToken } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      try {
        await refreshToken();
        const response = await axiosInstance.get("/tilaajataulu/");
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Virhe: datan hakeminen epäonnistui.");
      }
    };

    fetchData();
  }, []);

  async function downloadZip(y_tunnus: string, zip_nimi: string, kyselynro: number) {
    try {
      const response = await fetch(`/api/protected-zip/${y_tunnus}/${kyselynro}/`);
      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", y_tunnus + " - " + zip_nimi);
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      } else {
        // Handle error
        console.error("Error downloading zip file");
      }
    } catch (error) {
      console.error("Error downloading zip file:", error);
    }
  }

  return (
    <div id="tilaajataulu">
      <Box
        sx={{
          backgroundColor: theme.palette.background.paper,
          pt: 14,
          pb: 13,
          px: 2,
        }}
      >
        {error ? ( // there is an error
          <Typography
            variant="h6"
            color="error"
            sx={{ mt: 10 }}
          >
            {error}
          </Typography>
        ) : data.length === 0 ? ( // there is no error, but the data length is 0
          <Typography
            variant="h6"
            sx={{ mt: 20 }}
          >
            Tietokannasta ei löydy tilausdataa.
          </Typography>
        ) : (
          // else, there is no error and the data length is > 0
          <TableContainer>
            <Table
              sx={{ minWidth: 650 }}
              size="medium"
              aria-label="simple table"
            >
              <TableHead>
                <TableRow sx={{ backgroundColor: theme.palette.background.paper }}>
                  <TableCell>#</TableCell>
                  <TableCell>Yrityksen nimi</TableCell>
                  <TableCell>Y-tunnus</TableCell>
                  <TableCell>Markkinointinimi</TableCell>
                  <TableCell>Päätoimiala</TableCell>
                  <TableCell>Liikevaihdon kokoluokka</TableCell>
                  <TableCell>Postinumero</TableCell>
                  <TableCell>Tilaajan nimi</TableCell>
                  <TableCell>Sähköposti</TableCell>
                  <TableCell>Puhelin</TableCell>
                  <TableCell>Tilatut tutkimukset</TableCell>
                  <TableCell sx={{ fontWeight: 900, width: "600px" }}>Muut huomiot</TableCell>
                  <TableCell>Tilauspäivämäärä</TableCell>
                  <TableCell>Asiakas -raporttipaketti zip</TableCell>
                  <TableCell>Henkilöstö -raporttipaketti zip</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row: TilaajaData, index: number) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell>{row.yrityksen_nimi}</TableCell>
                    <TableCell>{row.y_tunnus}</TableCell>
                    <TableCell>{row.markkinointi_nimi}</TableCell>
                    <TableCell>{row.formatted_paatoimiala}</TableCell>
                    <TableCell>{row.formatted_kokoluokka}</TableCell>
                    <TableCell>{row.yrityksen_postinumero}</TableCell>
                    <TableCell>{row.tilaajan_nimi}</TableCell>
                    <TableCell>{row.yhteys_sposti}</TableCell>
                    <TableCell>{row.yhteys_puh}</TableCell>
                    <TableCell>{row.formatted_tilatut_tuotteet.join(", ")}</TableCell>
                    <TableCell>{row.muuta}</TableCell>
                    <TableCell>{row.pvm}</TableCell>
                    <TableCell>
                      <button
                        onClick={() => downloadZip(row.y_tunnus, "VUOSIPALAUTE - ASIAKKAAT.zip", 1)}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        asty-zip
                      </button>
                    </TableCell>

                    <TableCell>
                      <button
                        onClick={() => downloadZip(row.y_tunnus, "VUOSIPALAUTE - Henkilöstö.zip", 2)}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        hr-zip
                      </button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </div>
  );
};

export default Tilaajataulu;
