// state.tsx
import React, { createContext, useContext, useEffect, useState } from "react";

export const STATE_KEY = "vuosipalaute-state";

type GeneratedBackgrounds = {
  tausta_1: string;
  tausta_2: string;
  tausta_3: string;
  tausta_4: string;
  tausta_5: string;
  tausta_6: string[];
  tausta_7: string;
  tausta_8: string;
  tausta_9: string;
  tausta_10: string;
  tausta_11: string;
  tausta_12: string;
};

// default values for the background questionss
const generateBackgrounds = (): GeneratedBackgrounds => ({
  tausta_1: "",
  tausta_2: "",
  tausta_3: "",
  tausta_4: "",
  tausta_5: "",
  tausta_6: [],
  tausta_7: "",
  tausta_8: "",
  tausta_9: "1",
  tausta_10: "",
  tausta_11: "",
  tausta_12: "",
});

// Define the GeneratedQuestions type
type GeneratedQuestions = {
  [key in
    | `q${number}`
    | `q${number}_a1`
    | `q${number}_a2`
    | `q${number}_a3`
    | `q${number}_eos`]: key extends `q${number}` ? string | null : key extends `q${number}_eos` ? boolean : string;
};

// Helper function to generate the question fields
const generateQuestions = (count: number): GeneratedQuestions => {
  const questions: Partial<GeneratedQuestions> = {};

  for (let i = 1; i <= count; i++) {
    questions[`q${i}`] = null;
    questions[`q${i}_a1`] = "";
    questions[`q${i}_a2`] = "";
    questions[`q${i}_a3`] = "";
    questions[`q${i}_eos`] = false;
  }

  return questions as GeneratedQuestions;
};

// Generate the question fields for the ApplicationState type
// type GeneratedQuestions = typeof generatedQuestions;
const generatedQuestions = generateQuestions(10);
export type ApplicationState = {
  identifier?: string;
  yritysNimi?: string;
  lng?: string;
} & GeneratedQuestions &
  GeneratedBackgrounds & { [key: string]: any };

type AppStateContextType = {
  state: ApplicationState;
  setState: React.Dispatch<React.SetStateAction<ApplicationState>>;
  formId: string;
  setIsSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
  isSubmitted: boolean;
  resetState: () => void;
};

export const AppStateContext = createContext<AppStateContextType>({
  state: {
    identifier: "",
    yritysNimi: "",
    lng: "fi",
    ...generateQuestions(5),
    ...generateBackgrounds(),
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setState: () => {},
  formId: "",
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setIsSubmitted: () => {},
  isSubmitted: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  resetState: () => {},
});

interface AppProviderProps {
  children: React.ReactNode;
  formId: string;
  resetState?: () => void;
}

// Generate the default state for the questions
const defaultQuestionsState: GeneratedQuestions = {
  ...generatedQuestions,
};
const defaultBackgroundsState: GeneratedBackgrounds = {
  ...generateBackgrounds(),
};

export const getInitialState = (formId: string): ApplicationState => {
  return {
    identifier: "",
    yritysNimi: "",
    lng: "fi",
    formId: formId,
    ...defaultQuestionsState,
    ...defaultBackgroundsState,
  };
};

export function AppProvider({ children, formId, resetState: customResetState }: AppProviderProps) {
  const [state, setState] = useState<ApplicationState>(() => {
    const defaultState: ApplicationState = {
      identifier: "",
      yritysNimi: "",
      lng: "fi",
      formId: formId,
      ...defaultQuestionsState,
      ...defaultBackgroundsState,
    };

    // Try to retrieve state from local storage
    // Use formId to differentiate between forms
    const storedState = localStorage.getItem(`${STATE_KEY}-${formId}`);
    return storedState ? { ...defaultState, ...JSON.parse(storedState) } : defaultState;
  });

  const [isSubmitted, setIsSubmitted] = useState(false);

  // Save state to local storage whenever it changes
  useEffect(() => {
    localStorage.setItem(`${STATE_KEY}-${formId}`, JSON.stringify(state));
  }, [state, formId]);

  const resetState =
    customResetState ||
    (() => {
      setState(getInitialState(formId));
    });

  return (
    <AppStateContext.Provider value={{ state, setState, formId, setIsSubmitted, isSubmitted, resetState }}>
      {children}
    </AppStateContext.Provider>
  );
}

export function useAppState() {
  const context = useContext(AppStateContext);
  if (!context) {
    throw new Error("useAppState must be used within the AppProvider");
  }
  return context;
}
