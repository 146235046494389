import React from "react";

import Box from "@mui/material/Box";
import { Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";

import TervetuloaKappale from "../../CommonFormComponents/Tervetuloa/TervetuloaKappale";
import { NakokulmaTekstiProps } from "../../CommonFormComponents/types";

const HenkilostoNakokulma: React.FC<NakokulmaTekstiProps> = ({ sx }) => {
  const { t } = useTranslation();
  return (
    <Container
      sx={{
        margin: "1rem 0",
      }}
    >
      <Box>
        <Typography
          variant="h4"
          sx={{
            textAlign: "left",
            marginY: 4,
          }}
        >
          {t("henkilosto.henkilostonakokulma.otsikko")}
        </Typography>
        <TervetuloaKappale>{t("henkilosto.henkilostonakokulma.p1")}</TervetuloaKappale>
      </Box>
    </Container>
  );
};

export default HenkilostoNakokulma;
