const apiUrl =
  process.env.NODE_ENV === "production"
    ? "https://kiinteistopalvelut.toimialadata.fi/api/ilmoittautuminen/"
    : "http://localhost/api/ilmoittautuminen/";

const baseApiUrl =
  process.env.NODE_ENV === "production" ? "https://kiinteistopalvelut.toimialadata.fi/api" : "http://localhost/api";

const config = {
  apiUrl,
  baseApiUrl,
};

export default config;
