import { ReactElement } from "react";

import KyselySivu from "../CommonFormComponents/KyselysivunOsat/KyselySivu";
import TervetuloaSivu from "./TervetuloaSivu";
import KiitosSivu from "../CommonFormComponents/Kiitos/KiitosSivu";
import AsiakasTaustatietoSivu from "./AsiakasTaustatietoSivu";

export type KyselyRakenne = {
  polku: string;
  element: ReactElement;
}[];

export const Asiakaskyselyrakenne: KyselyRakenne = [
  {
    polku: "",
    element: (
      <TervetuloaSivu
        seuraavaSivu="1"
        buttonSeuraava="buttonAloitetaan"
      />
    ),
  },
  {
    polku: "1",
    element: (
      <KyselySivu
        kysymys="1"
        otsikko="asiakas.k1.otsikko"
        eriMielta="asiakas.k1.eriMielta"
        samaaMielta="asiakas.k1.samaaMielta"
        avo1="asiakas.mikaOllutHyvaa"
        avo2="asiakas.mitenToimiaParemmin"
        avo3={null}
        edellinenSivu=""
        seuraavaSivu="2"
      />
    ),
  },
  {
    polku: "2",
    element: (
      <KyselySivu
        kysymys="2"
        otsikko="asiakas.k2.otsikko"
        eriMielta="asiakas.k2.eriMielta"
        samaaMielta="asiakas.k3.samaaMielta"
        avo1="asiakas.mikaOllutHyvaa"
        avo2="asiakas.mitenToimiaParemmin"
        avo3={null}
        edellinenSivu="1"
        seuraavaSivu="3"
      />
    ),
  },
  {
    polku: "3",
    element: (
      <KyselySivu
        kysymys="3"
        otsikko="asiakas.k3.otsikko"
        eriMielta="asiakas.k3.eriMielta"
        samaaMielta="asiakas.k3.samaaMielta"
        avo1="asiakas.mikaOllutHyvaa"
        avo2="asiakas.mitenToimiaParemmin"
        avo3={null}
        edellinenSivu="2"
        seuraavaSivu="4"
      />
    ),
  },
  {
    polku: "4",
    element: (
      <KyselySivu
        kysymys="4"
        otsikko="asiakas.k4.otsikko"
        eriMielta="asiakas.k4.eriMielta"
        samaaMielta="asiakas.k4.samaaMielta"
        avo1="asiakas.mikaOllutHyvaa"
        avo2="asiakas.mitenToimiaParemmin"
        avo3={null}
        edellinenSivu="3"
        seuraavaSivu="5"
      />
    ),
  },
  {
    polku: "5",
    element: (
      <KyselySivu
        kysymys="5"
        otsikko="asiakas.k5.otsikko"
        eriMielta="asiakas.k5.eriMielta"
        samaaMielta="asiakas.k5.samaaMielta"
        avo1="asiakas.perustelusi"
        avo2={null}
        avo3={null}
        showEdellinen={true}
        edellinenSivu="4"
        seuraavaSivu="taustat"
      />
    ),
  },
  {
    polku: "taustat",
    element: (
      <AsiakasTaustatietoSivu
        edellinenSivu="5"
        seuraavaSivu="kiitos"
        lahetaData={true}
      />
    ),
  },
  {
    polku: "kiitos",
    element: (
      <KiitosSivu
        otsikko="asiakas.kiitos.otsikko"
        p1="asiakas.kiitos.p1"
        naytaNapit={false}
      />
    ),
  },
];
