import React from "react";

import { FieldErrors, UseFormRegister } from "react-hook-form";

import { Grid } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { SxProps } from "@mui/system";

import TaustaKysymysComponent from "./TaustaKysymysComponent";
import { Kysymys, IsFocusedState, AsiakasTaustaFormValues } from "../types";

interface TaustatietoKomponentitProps {
  sx?: SxProps<Theme>;
  maarittely_json: Kysymys[];
  register: UseFormRegister<AsiakasTaustaFormValues>;
  errors: FieldErrors<AsiakasTaustaFormValues>;
}

const TaustatietoKomponentit: React.FC<TaustatietoKomponentitProps> = ({ sx, maarittely_json, register, errors }) => {
  const [isFocused, setIsFocused] = React.useState<IsFocusedState>({ radio: false, checkbox: false });

  return (
    <Grid
      container
      spacing={1}
      sx={{
        m: 0,
        mx: 0,
      }}
    >
      {maarittely_json.map((kysymys: Kysymys, index: number) => (
        <React.Fragment key={index}>
          <TaustaKysymysComponent
            kysymys={kysymys}
            register={register}
            errors={errors}
            isFocused={isFocused}
            setIsFocused={setIsFocused}
          />
        </React.Fragment>
      ))}
    </Grid>
  );
};

export default TaustatietoKomponentit;
