export const toimialat = [
    { id: '1', label: 'Kiinteistöhuolto' },
    { id: '2', label: 'Infra-alueiden huolto' },
    { id: '3', label: 'Tekniset huoltopalvelut' },
    { id: '4', label: 'Teollisuuden kunnossapitopalvelut' },
    { id: '5', label: 'LVIS-huoltopalvelut' },
    { id: '6', label: 'Siivous' },
    { id: '7', label: 'Pesupalvelut' },
    { id: '8', label: 'Piha ja puutarha' },
    { id: '9', label: 'Nuohous' },
    { id: '10', label: 'Kattopalvelut' },
    { id: '11', label: 'Jätehuolto' },
    { id: '12', label: 'Suunnittelupalvelut' },
    { id: '13', label: 'Maanrakennuspalvelut' },
    { id: '14', label: 'Remonttipalvelut' },
    { id: '15', label: 'Turvallisuuspalvelut' },
]
