import React from "react";
import Typography from "@mui/material/Typography";

interface ValiotsikkoProps {
  otsikko: string;
}

const Valiotsikko: React.FC<ValiotsikkoProps> = ({ otsikko }) => {
  return (
    <Typography
      variant="h6"
      gutterBottom
      sx={{ textTransform: "uppercase", marginTop: 1 }}
    >
      {otsikko}
    </Typography>
  );
};

export default Valiotsikko;
